import React, {useCallback} from 'react'
import {FormattedDate, FormattedMessage, FormattedRelativeTime} from "react-intl";
import Stepper from "react-stepper-horizontal";
import {
    faChevronDown,
    faFileDownload,
    faFilePdf,
    faPencil,
    faCheck,
    faSignOutAlt,
    faStickyNote,
    faTrash,
    faRepeat,
    faCoins
} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TipBox from "./tipBox";
import Actions from "../helpers/actions";
import Functions from "../helpers/functions";
import CurrencyFormat from "react-currency-format";
import Avatar from "react-avatar";
import {AreaSeries, HighchartsSparkline, Tooltip} from "react-jsx-highcharts";


export default class ProductItem extends React.Component {
    constructor(props) {
        super(props);

        var totalProducts = 0;
        var totalSoldProducts = 0;

        if(this.props.data.length > 1){
            this.props.data.forEach((product,i)=>{
                totalProducts += parseInt(product.stock);
                totalSoldProducts += parseInt(product.stock_sold);
            });
        }


        this.state = {
            data:this.props.data,
            dropDownOpen:false,
            totalProducts:totalProducts,
            totalSoldProducts:totalSoldProducts,
        };
    }
    componentDidMount(){

    }

    componentWillReceiveProps(nextProps){
        this.setState(nextProps);
    }
    renderButton(type,data){
        var thiz =  this;
        switch(type){
            case "edit":
                if(true){
                    return(
                        <TipBox isOpen={this.state.toolTipOpen} attachment={"bottom center"} element={()=>{
                            return(
                                <a className="action columns shrink button icon yellow"
                                   onClick={()=>{
                                       thiz.props.updateProduct(data)
                                   }}
                                >
                                    <FontAwesomeIcon icon={faPencil} size="lg"/>
                                </a>
                            )
                        }} content={()=>{
                            return(
                                <p className={"tipBoxWrapper small"}>
                                    <FormattedMessage
                                        id="actionButton.edit"
                                    />
                                </p>
                            )
                        }}/>
                    );
                }
                break;
            case "remove":
                if(true){
                    return(
                        <TipBox isOpen={this.state.toolTipOpen} attachment={"bottom center"} element={()=>{
                            return(
                                <a className="action columns shrink button icon red"
                                   onClick={()=>{
                                       thiz.props.deleteProduct(data.id)
                                   }}
                                >
                                    <FontAwesomeIcon icon={faTrash} size="lg"/>
                                </a>
                            )
                        }} content={()=>{
                            return(
                                <p className={"tipBoxWrapper small"}>
                                    <FormattedMessage
                                        id="actionButton.delete"
                                    />
                                </p>
                            )
                        }}/>
                    );
                }
                break;
        }
    }
    render(){
        return(
            <li className={"user item"+(this.state.dropDownOpen?" open":"")}>
                {this.renderMainProduct()}
                {this.renderDropDown()}
            </li>
        );
    }
    renderMainProduct(){
        var data = this.state.data[0];
        if(this.state.data.length > 1){
            return(
                <div className={"row align-middle"}>
                    <div className={"columns shrink name"}>
                        <div className={"title"}>
                            <div className={"row collapse"}>
                                <div className={"columns"}>
                                    <a onClick={()=>{
                                        //this.props.openSidePanel("products",data.id)
                                    }}><b title={data.name}>{data.name}</b></a>
                                    <small>Birden fazla fiyat</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"columns small-1"}>
                        <div>
                            <b className={"noEllipsis"}>{this.state.totalSoldProducts} / {this.state.totalProducts}</b>
                        </div>
                    </div>
                    <div className={"columns small-3 name"}>
                        <div><b>
                            <FormattedDate
                                value={new Date(this.state.data[this.state.data.length - 1].startDate).getTime()}
                                year="numeric"
                                month="long"
                                day="2-digit"
                                hour='numeric'
                                minute='numeric'
                            />
                        </b></div>
                        <small style={{color:"green"}}>Başladı</small>
                    </div>
                    <div className={"columns"}>
                        <div><b className={"noEllipsis"}>
                            <FormattedDate
                                value={new Date(this.state.data[this.state.data.length - 1].endDate).getTime()}
                                year="numeric"
                                month="long"
                                day="2-digit"
                                hour='numeric'
                                minute='numeric'
                            />
                        </b></div>
                        <small style={{display:"none"}}><FormattedRelativeTime value={((new Date(data.endDate).getTime()) - new Date().getTime())} numeric="auto" updateIntervalInSeconds={10} /> bitiyor</small>
                    </div>
                    <div className={"columns"} style={{paddingRight:0}}>
                        <div className={"row collapse align-right"}>
                            {this.renderButton("repeat",data)}
                        </div>
                    </div>
                    <div className={"columns shrink"}>
                        <div className={"arrow"} onClick={()=>{
                            this.setState({dropDownOpen:!this.state.dropDownOpen});
                        }}>
                            <FontAwesomeIcon icon={faChevronDown} size="x1"/>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={"row align-middle user"}>
                    <div className={"columns name shrink"}>
                        <div className={"title"}>
                            <div className={"row align-middle collapse"}>
                                <div className={"columns"}>
                                    <a onClick={()=>{
                                        //this.props.openSidePanel("project",data.id)
                                    }}><b>{data.name}</b></a>
                                    <small>Süre; {Functions.secondToMinute(data.duration)}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"columns name projectName shrink"}>
                        <div className={"title"}>

                        </div>
                    </div>
                    <div className={"columns small-4 name stepper noPaddingRight"}>
                        <Stepper titleTop={4} circleTop={0} size={22} circleFontSize={12} defaultBorderStyle={{lineHeight:0}} steps={ [{title:"Pending"}, {title:"In Review"}, {title:"Declined" }, {title:"Approved"}] } titleFontSize={12} defaultTitleColor={"#666"} activeTitleColor={"#4085c7"} completeTitleColor={"#4085c7"} activeBorderColor={"#666"} defaultBarColor={"#CCC"}
                                 activeStep={3} />
                    </div>
                    <div className={"columns"}>
                        <div className={"row collapse align-right"}>
                            {this.renderButton("edit",data)}
                            {this.renderButton("remove",data)}
                        </div>
                    </div>
                </div>
            );
        }
    }
    renderDropDown(){
        if(this.state.dropDownOpen && this.state.data.length > 1){
            return(
                <div className={"dropDown"}>
                    <ul>
                        {this.renderProducts()}
                    </ul>
                </div>
            );
        }
    }
    renderProducts(){
        return this.state.data.map((data,key)=>{
            return(
                <li className={"row align-middle"} key={"alt"+key}>
                    <div className={"columns shrink name"}>
                        <div className={"title"}>
                            <div className={"row collapse"}>
                                <div className={"columns"}>
                                    <a onClick={()=>{
                                        //this.props.openSidePanel("products",data.id)
                                    }}><b title={data.name}>{data.name}</b></a>
                                    <small><b style={{display:"inline-block",fontSize:10}}><CurrencyFormat value={data.priceEUR} displayType={'text'} thousandSeparator={true} suffix={' TL'} /></b> yerine <b style={{display:"inline-block",fontSize:10}}><CurrencyFormat value={((parseFloat(data.priceEUR) / 100) * (100 - parseFloat(data.autoExchangeDiscountPercent)))} displayType={'text'} decimalScale={2} thousandSeparator={true} suffix={' TL'} /></b></small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"columns small-1"}>
                        <div>
                            <b className={"noEllipsis"}>{data.stock_sold} / {data.stock}</b>
                        </div>
                    </div>
                    <div className={"columns small-3 name"}>
                        <div><b>
                            <FormattedDate
                                value={new Date(data.startDate).getTime()}
                                year="numeric"
                                month="long"
                                day="2-digit"
                                hour='numeric'
                                minute='numeric'
                            />
                        </b></div>
                        <small style={{color:"green"}}>Başladı</small>
                    </div>
                    <div className={"columns small-2 name"} style={{paddingLeft:22}}>
                        <div><b className={"noEllipsis"}>
                            <FormattedDate
                                value={new Date(data.endDate).getTime()}
                                year="numeric"
                                month="long"
                                day="2-digit"
                                hour='numeric'
                                minute='numeric'
                            />
                        </b></div>
                        <small style={{display:"none"}}><FormattedRelativeTime value={((new Date(data.endDate).getTime()) - new Date().getTime())} numeric="auto" updateIntervalInSeconds={10} /> bitiyor</small>
                    </div>
                    <div className={"columns"}>
                        <div className={"row collapse align-right"}>
                            {this.renderButton("donate",data)}
                            {this.renderButton("edit",data)}
                            {this.renderButton("remove",data)}
                        </div>
                    </div>
                </li>
            );
        });
    }
}

