
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import {IntlProvider} from 'react-intl';
import * as moment from 'moment'
import 'moment-timezone';

import cogoToast from 'cogo-toast';

import './assets/css/App.scss';

import Auth from './views/auth/main';
import Main from "./views/main";
import Error_404 from "./views/error/404";


import Functions from './helpers/functions';
import StoreLib from './helpers/store';
import ActionsLib from './helpers/actions';
let Actions,Store;


const messages = {
    'en': require('./assets/languages/en.json'),
    'tr': require('./assets/languages/tr.json')
};


class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language:navigator.language,
            isLogged:false,
            startURI:window.location.pathname,
            isRedirecting:false,
            appReady:false
        };

        this.hideToast = false;

    }

    async componentDidMount(){
        this.setLanguage("en");
        var data = await localStorage.getItem('db');
        Store = StoreLib.init(data);
        Actions = ActionsLib.init(Store);

        this.setState({isLogged:Actions.getUser().isLogged,appReady:true});

        Actions.track();
        Actions.register("notification",this.showNotification.bind(this));
        Actions.register("showToast",this.showToastTrigger.bind(this));
        Actions.register("showLoadingToast",this.showLoadingToastTrigger.bind(this));
        Actions.register("setLanguage",this.setLanguage.bind(this));
        Actions.register("hideToast",this.hideToastTrigger.bind(this));

        var thiz = this;
        setTimeout(()=>{
            //this.setLanguage("en");

            if(!Functions.isMobile() && 0){
                thiz.requestNotificationPermission();

                // Get Instance ID token. Initially this makes a network call, once retrieved
                // subsequent calls to getToken will return from cache.
                Actions.notifications.getToken().then((currentToken) => {
                    if (currentToken) {
                        console.log(currentToken);
                    } else {
                        console.log('No Instance ID token available. Request permission to generate one.');
                    }
                }).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                });
            }
        },3000);
    }
    setLanguage(lang){
        this.setState({language:lang},()=>{
            moment.locale(lang);
        });
    }
    showToastTrigger(data){
        const { hide } = cogoToast.success(data.message,{position:"bottom-right"});
        this.hideMessageToast = hide;
    }
    showLoadingToastTrigger(){
        const { hide } = cogoToast.loading('Loading data',{position:"bottom-right",hideAfter:0});
        this.hideToast = hide;}
    hideToastTrigger(fn){
        fn = (typeof fn == "function"?fn:()=>{});
        if(typeof this.hideToast == "function"){
            this.hideToast();
            fn();
        }
    }
    showNotification(data){
        new Notification(data.message);
    }

    userAction(action,data){
        console.log(data);
        Actions.setUser(data);
        Actions.setUserToken(data.token_web);

        Actions.track("event",{
            category: 'User',
            action: 'Logged an account'
        });
        Actions.track("timing",{
            category: 'Login server',
            variable: 'load',
            value: 20, // in milliseconds
            label: 'Login server timing'
        });
        this.setState({isLogged:true,isRedirecting:true},()=>{
            this.setState({isRedirecting:false});
        });
    }
    requestNotificationPermission(fn) {
        fn = (typeof fn == "undefined"?()=>{}:fn);
        if ("Notification" in window) {
            if (
                Notification.permission !== "denied" &&
                Notification.permission !== "granted"
            ) {
                Actions.notifications.requestPermission()
                    .then(async function() {
                        //const token = await Actions.notifications.getToken();
                        //console.log("Token: "+token)
                    })
                    .catch(function(err) {
                        console.log("Unable to get permission to notify.", err);
                    });
                navigator.serviceWorker.addEventListener("message", (message) => console.log(message));
            }
        }
    }
    sendNotification(){
        if ("Notification" in window && Notification.permission === "granted") {
            new Notification("Welcome to app");
        } else {
            this.requestNotificationPermission();
        }
    }

    render(){
        if(this.state.appReady){
            return (
                <IntlProvider locale={this.state.language} messages={messages[this.state.language]} timeZone="Europe/Istanbul" formats={{
                    time: {
                        '24hour': {hour12: false}
                    }
                }}>
                    <Router>
                        <div className="App">
                            <Switch>
                                {this.renderRouter()}
                                {this.state.isRedirecting?(
                                    <Redirect
                                        to={{
                                            pathname: this.state.startURI,
                                        }}
                                    />
                                ):(null)}
                                <Route
                                    path="/"
                                    render={props =>
                                        this.state.isLogged ? (
                                            <div>
                                                <Route path="/" render={(props) => <Main {...props} /> }/>
                                            </div>
                                        ) : (
                                            <Redirect
                                                to={{
                                                    pathname: "/auth/login",
                                                    state: { from: props.location }
                                                }}
                                            />
                                        )
                                    }
                                />
                                <Route component={Error_404} />
                            </Switch>
                        </div>
                    </Router>
                </IntlProvider>
            );
        } else {
            return null;
        }
    }

    renderRouter(){
        if(!this.state.isLogged){
            return(
                <Route path="/auth" render={(props) => <Auth userAction={this.userAction.bind(this)} {...props} /> } />
            );
        }
    }
}

export default App;
