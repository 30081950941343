import React from 'react';
import {Link } from "react-router-dom";
import Actions from '../../helpers/actions';
import Functions from '../../helpers/functions';
import Locale from '../../assets/languages/locale.json';

import TipBox from '../../components/tipBox';

import {FormattedMessage,FormattedNumber,FormattedDate,FormattedTime,FormattedRelativeTime} from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faLifeRing,
    faChevronRight,
    faChevronLeft,
    faEnvelope,
    faInfoCircle,
    faSignOutAlt,
    faFileExport,
    faFileDownload,
    faPencil,
    faTrash,
    faFilePdf
} from '@fortawesome/pro-regular-svg-icons'
import { StickyContainer, Sticky } from 'react-sticky';
import Stepper from 'react-stepper-horizontal';
import CsvDownloader from 'react-csv-downloader';
import ReactPaginate from 'react-paginate';

import Select from 'react-select';
import {Line} from "rc-progress";
import Highcharts from 'highcharts';
import {
    HighchartsChart,
    Chart,
    withHighcharts,
    XAxis,
    YAxis,
    Tooltip,
    Title,
    Subtitle,
    Legend,
    LineSeries,
    SplineSeries,
    AreaSeries,
    HighchartsSparkline,
    PieSeries
} from 'react-jsx-highcharts';
import Switch from "react-ios-switch/lib";
import Avatar from "react-avatar";
import ContentLoader from "react-content-loader";


const selectStyle = {
    option: (provided, state) => ({
        ...provided,
    }),
    control: (provided, state) => ({
        ...provided,
        position:'relative',
        zIndex:1005
    }),
}


class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter:{
                state:{ value: '0', label: 'All' },
                status:{ value: '0', label: 'All' },
                order:{ value: '0', label: 'Name A-Z' }
            },
            data:[]
        };
        Highcharts.setOptions({dateTime:Locale.tr.dateTime});
    }
    componentDidMount(){
        this.getUsers();
    }
    async getUsers(){
        this.setState({loading:true});
        try {
            var url = Functions.generateURI("/users/getAllUsers",{});

            var response = await fetch(url.address, url.header);
            response = await response.json();

            if(response.status){
                this.setState({data:response.data},()=>{

                });
            } else {

            }
        } catch(error) {

        }
    }
    changeFilter(target,value){
        var filter = this.state.filter;
        filter[target] = value;
        this.setState({filter:filter},()=>{

        });
        return false;
    }
    render(){
        return (
            <div id={"users"}>
                <StickyContainer>
                    <div className={"row"}>
                        <div className={"columns small-12 medium-4"}>
                            <div className={"stickyWrapper"}>
                                <div>
                                    <Sticky topOffset={-62}>

                                        {({
                                              isSticky,
                                              style,
                                          }) => (
                                            <div className={"row collapse"}>
                                                <div className={"columns small-12"} style={{...style, marginTop: isSticky ? '62px' : '0px'}} >
                                                    <div className={"block info"}>
                                                        <div className={"group"}>
                                                            <div className={"container"}>
                                                                <b>Stattus</b>
                                                                <p>
                                                                    Total <a onClick={()=>{this.changeFilter("status",0)}}><b>0</b></a> user found.

                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Sticky>
                                </div>
                            </div>
                        </div>
                        <div className={"columns small medium-8"}>
                            <Sticky topOffset={-38}>

                                {({
                                      isSticky,
                                      style,
                                  }) => (
                                    <div id={"filter"} className={"columns small-12 block noMargin"} style={{...style, marginTop: isSticky ? '38px' : '0px'}} >
                                        <div>
                                            <div className="row">
                                                <div className="columns small-3">
                                                    <div className="inputHeader">Type</div>
                                                    <Select
                                                        styles={selectStyle}
                                                        value={this.state.filter.state}
                                                        onChange={(val)=>{
                                                            this.changeFilter("state",val);
                                                        }}
                                                        options={[
                                                            { value: '0', label: 'All' },
                                                            { value: '1', label: 'Producer' },
                                                            { value: '2', label: 'Customer' },
                                                        ]}
                                                    />
                                                </div>
                                                <div className="columns small-3">
                                                    <div className="inputHeader">Status</div>
                                                    <Select
                                                        styles={selectStyle}
                                                        value={this.state.filter.status}
                                                        onChange={(val)=>{
                                                            this.changeFilter("status",val);
                                                        }}
                                                        options={[
                                                            { value: '0', label: 'All' },
                                                            { value: 'chocolate', label: 'Active' },
                                                            { value: 'strawberry', label: 'Passive' },
                                                            { value: 'vanilla', label: 'Banned' }
                                                        ]}
                                                    />
                                                </div>
                                                <div className="columns">
                                                    <div className={"row align-right"}>
                                                        <div className="columns small-7">
                                                            <div className="inputHeader">Order</div>
                                                            <Select
                                                                styles={selectStyle}
                                                                value={this.state.filter.order}
                                                                onChange={(val)=>{
                                                                    this.changeFilter("order",val);
                                                                }}
                                                                options={[
                                                                    { value: 'name_az', label: 'Name A-Z' },
                                                                    { value: 'name_za', label: 'Name Z-A' },
                                                                ]}
                                                            />
                                                        </div>
                                                        <div className={"column shrink noPaddingLeft"}>
                                                            <CsvDownloader
                                                                filename="uyeler"
                                                                separator=";"
                                                                columns={[{
                                                                    id: 'first',
                                                                    displayName: 'First column'
                                                                }, {
                                                                    id: 'second',
                                                                    displayName: 'Second column'
                                                                }]}
                                                                datas={[{
                                                                    first: 'foo',
                                                                    second: 'bar'
                                                                }, {
                                                                    first: 'foobar',
                                                                    second: 'foobar'
                                                                }]}>
                                                                <div className={"export"}><FontAwesomeIcon icon={faFileDownload} size="1x"/></div>
                                                            </CsvDownloader>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Sticky>
                            <div className={"columns small-12 block marginTop"}>
                                <div className="list">
                                    <div className={"header row"}>
                                        <div className={"columns shrink"}>
                                            <div className={"title"}><b>Kullanıcı</b></div>
                                        </div>
                                        <div className={"columns small-2"}>
                                            <b>İşlem</b>
                                        </div>
                                    </div>
                                    <div className={"container"}>
                                        <ul>
                                            {this.renderUserList()}
                                        </ul>
                                        <div className={"pagination"}>
                                            <ReactPaginate
                                                previousLabel={<FontAwesomeIcon icon={faChevronLeft} size="lg"/>}
                                                nextLabel={<FontAwesomeIcon icon={faChevronRight} size="lg"/>}
                                                breakLabel={'...'}
                                                breakClassName={'break-me'}
                                                pageCount={22}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={()=>{
                                                    Functions.scrollTo(0);
                                                }}
                                                containerClassName={'pagination'}
                                                subContainerClassName={'pages pagination'}
                                                activeClassName={'active'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </StickyContainer>
            </div>
        );
    }
    renderUserList(){
        if(this.state.data.length > 0){
            return this.state.data.map((data,key)=>{
                var randStep = 0;
                return(
                    <li className={"row align-middle user"} key={"u_"+key}>
                        <div className={"columns name shrink"}>
                            <div className={"title"}>
                                <div className={"row collapse"}>
                                    <div className={"columns shrink"}>
                                        <img className="image transition" src={Functions.fakeProfileImage()}/>
                                    </div>
                                    <div className={"columns"}>
                                        <a onClick={()=>{
                                            this.props.openSidePanel("users",3)
                                        }}><b>Serdar Bakır</b></a>
                                        <small>Kalite Kontrol</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"columns name small-3"}>
                            <div className={"title"}>
                                <div className={"row collapse"}>
                                    <div className={"columns"}>
                                        <Link to={"/activity/"+1}>
                                            <div>142 İşlem</div>
                                        </Link>
                                        <small>Son 7 gün baz alınmıştır</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"columns small-2"}>
                            <div className={"title"}>
                                <HighchartsSparkline>
                                    <Tooltip
                                        outside
                                        borderWidth={1}
                                        shadow={false}
                                        hideDelay={0}
                                        padding={8}
                                    />
                                    <AreaSeries name={"Tamamlanan iş"} data={[
                                        ["1/10/2019",1],
                                        ["2/10/2019",3],
                                        ["3/10/2019",5],
                                        ["4/10/2019",4],
                                        ["5/10/2019",8],
                                        ["6/10/2019",4],
                                        ["7/10/2019",5]
                                    ]} />
                                </HighchartsSparkline>
                            </div>
                        </div>
                        <div className={"columns"}>
                            <div className={"row align-right"}>
                                <div className={"columns shrink"}>
                                    <Switch
                                        checked={true}
                                        className={"switch small"}
                                        handleColor="white"
                                        offColor="white"
                                        onChange={(checked) => {

                                        }}
                                        onColor="rgb(102, 170, 234)"
                                    />
                                </div>
                                <div className={"columns shrink noPaddingLeft"}>
                                    <Link to={"google"} className={"button icon small red"}>
                                        <FontAwesomeIcon icon={faTrash} size="xs"/>
                                    </Link>
                                </div>
                                <div className={"columns shrink noPaddingLeft"}>
                                    <Link to={"google"} className={"button icon small yellow"}>
                                        <FontAwesomeIcon icon={faPencil} size="xs"/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </li>
                );
            });
        } else {
            var shimmers = [{},{},{},{},{},{},{},{},{},{}];
            return shimmers.map((data,i)=>{
                return(
                    <li className={"row shimmer"} key={"u_"+i}>
                        <div className={"columns small-12"}>
                            <ContentLoader
                                width={620}
                                height={50}
                            >
                                <rect x="0" y="0" rx="25" ry="25" width="50" height="50" />
                                <rect x="60" y="7" rx="4" ry="4" width="240" height="16" />
                                <rect x="60" y="30" rx="4" ry="4" width="240" height="10" />
                                <rect x="350" y="7" rx="4" ry="4" width="300" height="16" />
                                <rect x="350" y="30" rx="4" ry="4" width="300" height="10" />
                            </ContentLoader>
                        </div>
                    </li>
                );
            })
        }
    }
}
export default withHighcharts(Users, Highcharts);
