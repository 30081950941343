import React, {useCallback} from 'react'

import {Link } from "react-router-dom";
import Actions from '../../helpers/actions';
import Functions from '../../helpers/functions';

import PdfLink from '../../components/pdfLink';
import {
    PDFDownloadLink,
    PDFViewer,
    Text,
    Document,
    Font,
    Page,
    StyleSheet,
    Image,
    View,
} from '@react-pdf/renderer';

Font.register( {
    family: 'Open Sans',
    src: `/assets/fonts/Open_Sans/OpenSans-Bold.ttf`,
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4',
        fontFamily: 'Open Sans'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
        fontFamily: 'Open Sans'
    }
});



// Create Document Component
class MyDocument extends React.Component {
    render(){
        return(
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                        <Text>Section #1</Text>
                    </View>
                    <View style={styles.section}>
                        <Text>Section #2</Text>
                    </View>
                    <View style={styles.section}>
                        <Text>Section #1</Text>
                    </View>
                    <View style={styles.section}>
                        <Text>Section #2</Text>
                    </View>
                    <View style={styles.section}>
                        <Text>Section #1</Text>
                    </View>
                    <View style={styles.section}>
                        <Text>Section #2</Text>
                    </View>
                    <View style={styles.section}>
                        <Text>Section #1</Text>
                    </View>
                    <View style={styles.section}>
                        <Text>Section #2</Text>
                    </View>
                    <View style={styles.section}>
                        <Text>Section #1</Text>
                    </View>
                    <View style={styles.section}>
                        <Text>Section #2</Text>
                    </View>
                    <View style={styles.section}>
                        <Text>Section #1</Text>
                    </View>
                    <View style={styles.section}>
                        <Text>Section #2</Text>
                    </View>
                    <View style={styles.section}>
                        <Text>Section #1</Text>
                    </View>
                    <View style={styles.section}>
                        <Text>Section #2</Text>
                    </View>
                    <View style={styles.section}>
                        <Text>Section #1</Text>
                    </View>
                    <View style={styles.section}>
                        <Text>Section #2</Text>
                    </View>
                    <View style={styles.section}>
                        <Text>Section #1</Text>
                    </View>
                    <View style={styles.section}>
                        <Text>Section #2</Text>
                    </View>
                    <View style={styles.section}>
                        <Text>Section #1</Text>
                    </View>
                    <View style={styles.section}>
                        <Text>Section #2</Text>
                    </View>
                    <View style={styles.section}>
                        <Text>Section #1</Text>
                    </View>
                    <View style={styles.section}>
                        <Text>Section #2</Text>
                    </View>
                </Page>
            </Document>
        );
    }
};

export default class Pdf extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    componentDidMount(){


    }
    render(){
        return (
            <div style={{fontFamily: 'Arial'}}>
                <PDFViewer style={{width:600,height:600}}>
                    <MyDocument />
                </PDFViewer>
                <PdfLink document={<MyDocument />}/>
            </div>
        );
    }
}

