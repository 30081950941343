import React, {useCallback} from 'react'
import {Link } from "react-router-dom";
import Actions from '../../helpers/actions';
import Functions from '../../helpers/functions';
import ReactPaginate from 'react-paginate';
import GoogleMapReact from 'google-map-react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';



export default class MapGoogle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            center: {
                lat: 39.948966,
                lng: 32.661146
            },
            zoom: 11,
            address:""
        };
        this.directionRespond = {
            distance:{},
            duration:{}
        };
        this.maps = false;
        this.map = false;
    }
    handleChange = address => {
        this.setState({ address });
    };

    handleSelect = address => {
        this.setState({address:address});
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                console.log('Success', latLng)
                this.setState({
                    center:{lat:latLng.lat,lng:latLng.lng}
                });
                this.map.panTo({lat:latLng.lat,lng:latLng.lng});
            })
            .catch(error => console.error('Error', error));
    };
    componentDidMount(){

    }
    apiIsLoaded = (map, maps) => {
        if (map) {
            this.maps = maps;
            this.map = map;
            this.calcRoute("39.951004,32.649623","39.877021,32.656175",map,maps);
        }
    }
    calcRoute(from,to,map,maps) {

        var icons = {
            start: new maps.MarkerImage(
                // URL
                'https://maps.google.com/mapfiles/kml/shapes/library_maps.png',
                // (width,height)
                new maps.Size( 44, 32 ),
                // The origin point (x,y)
                new maps.Point( 0, 0 ),
                // The anchor point (x,y)
                new maps.Point( 22, 32 )
            ),
            end: new maps.MarkerImage(
                // URL
                'https://maps.google.com/mapfiles/kml/shapes/schools_maps.png',
                // (width,height)
                new maps.Size( 44, 32 ),
                // The origin point (x,y)
                new maps.Point( 0, 0 ),
                // The anchor point (x,y)
                new maps.Point( 22, 32 )
            )
        };


        const directionsService = new maps.DirectionsService();
        const directionsDisplay = new maps.DirectionsRenderer({suppressMarkers: true});

        directionsService.route({
            origin: from,
            destination: to,
            waypoints: [
                {
                    location: '39.904952, 32.647963',
                    stopover: true
                },
                {
                    location: '39.939088, 32.649548',
                    stopover: true
                },
            ],
            optimizeWaypoints: true,
            travelMode: 'DRIVING',
            drivingOptions: {
                departureTime: new Date(Date.now()),  // for the time N milliseconds from now.
                trafficModel: 'optimistic'
            }
        }, (response, status) => {

            console.log(response);

            if(true){
                this.directionRespond = {
                    distance:response.routes[0].legs[0].distance,
                    duration:response.routes[0].legs[0].duration
                }
            }

            if (status === 'OK') {
                directionsDisplay.setDirections(response);
                directionsDisplay.setMap(map);


                new maps.Marker({
                    position: response.routes[0].legs[0].start_location,
                    map: map,
                    icon: icons.start,
                    title: "title"
                });

                response.routes[0].legs.forEach((leg)=>{
                    new maps.Marker({
                        position: leg.end_location,
                        map: map,
                        icon: icons.end,
                        title: "title"
                    });
                })
            } else {
                window.alert('Directions request failed due to ' + status);
            }
        });
    }
    render(){
        return (
            <div>
                <h2>Map Google {JSON.stringify(this.state.center)}</h2>
                <div className={"row"}>
                    <div className={"columns small-12"}>
                        <PlacesAutocomplete
                            value={this.state.address}
                            onChange={this.handleChange}
                            onSelect={this.handleSelect}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <input
                                        {...getInputProps({
                                            placeholder: 'Search Places ...',
                                            className: 'location-search-input',
                                        })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                    <div className={"columns small-12"}>
                        <div style={{ height:500,position:'relative' }}>
                            {JSON.stringify(this.directionRespond.distance.text)+" @ "+(this.directionRespond.distance.value / 1000)+" km"}
                            <GoogleMapReact
                                bootstrapURLKeys={{ key:Functions.config.google.map.KEY}}
                                defaultCenter={this.state.center}
                                defaultZoom={this.state.zoom}
                                yesIWantToUseGoogleMapApiInternals
                                onGoogleApiLoaded={({ map, maps }) => this.apiIsLoaded(map, maps)}
                            >
                            </GoogleMapReact>
                        </div>
                    </div>
                    <div className={"columns small-12"}>
                        <ReactPaginate
                            previousLabel={'previous'}
                            nextLabel={'next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={22}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={()=>{

                            }}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

