import React from 'react';
import {Link } from "react-router-dom";
import Actions from '../../helpers/actions';
import Functions from '../../helpers/functions';
import Locale from '../../assets/languages/locale.json';
import TipBox from '../../components/tipBox';
import DateRangePicker from '../../components/dateRangePicker';

import {FormattedMessage,FormattedNumber,FormattedDate,FormattedTime,FormattedRelativeTime} from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronRight,faInfoCircle,faChartLineDown,faChartLine,faPrint} from '@fortawesome/pro-regular-svg-icons'
import { StickyContainer, Sticky } from 'react-sticky';
import Stepper from 'react-stepper-horizontal';

import Select from 'react-select';
import {Line} from "rc-progress";
import Highcharts from 'highcharts';
import {
    HighchartsChart, Chart, withHighcharts, XAxis, YAxis, Tooltip, Title, Subtitle, Legend,ColumnSeries,PieSeries, LineSeries, SplineSeries, AreaSeries,AreaSplineSeries, Annotation
} from 'react-jsx-highcharts';
import ReactToPrint from "react-to-print";

require("highcharts/modules/annotations")(Highcharts);


class Analytics extends React.Component {
    constructor(props) {
        super(props);
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var data1 = [];
        var data2 = [];

        var date1 = new Date(y, m, 0);
        var date2 = new Date(y, m - 1, 0);

        for(var i =0;i<30;i++){
            date1.setDate(date1.getDate() + 1);
            data1.push([Functions.getFormattedDate(date1,"DD MMM YYYY"), (Math.floor(Math.random() * 20) + 1 )]);

            date2.setDate(date2.getDate() + 1);
            data2.push([Functions.getFormattedDate(date2,"DD MMM YYYY"), (Math.floor(Math.random() * 20) + 1 )]);
        }

        this.state = {
            dateTimeRange:[
                {startDate:new Date(y, m, 1),endDate:new Date(y, m + 1, 0)},
                {startDate:new Date(y, m - 1, 1),endDate:new Date(y, m - 0, 0)},
            ],
            filter:{
                state:{ value: '0', label: 'All' },
                session:{ value: '0', label: 'All times' },
            },
            data1:data1,
            data2:data2
        };

        var chartOptions = {
            dateTime:Locale.tr.dateTime,
            legend:{

            },
            yAxis:{
                endOnTick: false,
                maxPadding: 0
            },
            xAxis: {
                crosshair: {
                    width: 20,
                    color: 'rgba(204, 214, 235, 0.25)'
                },
                categories: this.state.data1,
                labels:{
                    formatter: function() {
                        var date = this.value.toString().split(',')[0];
                        date = Functions.getFormattedDate(date,"DD / MM");
                        return date;
                    }
                }
            }
        }

        Highcharts.setOptions(chartOptions);
    }
    componentDidMount(){
        this.dataLoader = false;
        setTimeout(()=>{
            Actions.emit("showLoadingToast");
            setTimeout(()=>{
                Actions.emit("hideToast");
                Actions.emit("showToast",{message:"All data loaded"});
            },2000)
        },100);
    }
    componentWillMount(){
        clearTimeout(this.dataLoader);
        this.dataLoader = false;
        Actions.emit("hideToast");
    }
    changeFilter(target,value){
        var filter = this.state.filter;
        filter[target] = value;
        this.setState({filter:filter},()=>{

        });
        return false;
    }
    render(){
        return (
            <div id={"analytics"}>
                <StickyContainer>
                    <div className={"row"}>
                        <div className={"columns small-12 medium-4"}>
                            <div className={"stickyWrapper"}>
                                <div>
                                    <Sticky topOffset={-62}>

                                        {({
                                              isSticky,
                                              style,
                                          }) => (
                                            <div className={"row collapse"}>
                                                <div className={"columns small-12"} style={{...style, marginTop: isSticky ? '62px' : '0px'}} >
                                                    <div className={"block info"}>
                                                        <div className={"group"}>
                                                            <div className={"container"}>
                                                                <b>In selected dates</b>
                                                                <p>
                                                                    <a onClick={()=>{this.changeFilter("status",{ value: 'strawberry', label: 'Listen' });}}><b>0</b></a> music listened <a><b>0</b></a> added favorites and <a><b>0</b></a> sold and <a><b>0</b></a> special request recieved.

                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className={"group"}>
                                                            <div className={"container"}>
                                                                <b>Performance</b>
                                                                <HighchartsChart plotOptions={{
                                                                    series: {

                                                                    },
                                                                }} >
                                                                    <Chart height={120} backgroundColor={"transparent"} margin={20} marginLeft={32}/>
                                                                    <XAxis tickInterval={2}>
                                                                        <XAxis.Title>Time</XAxis.Title>
                                                                    </XAxis>

                                                                    <Tooltip valueSuffix=" music" />
                                                                    <YAxis>
                                                                        <SplineSeries name="Listened" data={[0, 0, 12, 15, 11, 21, 15, 18]} />
                                                                        <SplineSeries name="Bought" data={[1, 3, 7, 15, 13, 4, 8, 21]} />
                                                                    </YAxis>
                                                                </HighchartsChart>
                                                            </div>
                                                        </div>
                                                        <div className={"group"}>
                                                            <div className={"container"}>
                                                                <b>Steps</b>
                                                                <div style={{marginTop:16}}>
                                                                    <HighchartsChart>
                                                                        <Chart backgroundColor={"transparent"} margin={0} height={200}/>
                                                                        <Legend />

                                                                        <XAxis categories={[]} />
                                                                        <Tooltip  valueSuffix="" />
                                                                        <YAxis>
                                                                            <PieSeries name="process" data={[{
                                                                                name: 'Added',
                                                                                y: 0
                                                                            }, {
                                                                                name: 'Removed',
                                                                                y: 0
                                                                            }, {
                                                                                name: 'Sold',
                                                                                y: 0
                                                                            }, {
                                                                                name: 'Refund',
                                                                                y: 0
                                                                            }]} />
                                                                        </YAxis>
                                                                    </HighchartsChart>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Sticky>
                                </div>
                            </div>
                        </div>
                        <div className={"columns small medium-8"}>
                            <Sticky topOffset={-38}>

                                {({
                                      isSticky,
                                      style,
                                  }) => (
                                    <div id={"filter"} className={"columns small-12 block noMargin"} style={{...style, marginTop: isSticky ? '38px' : '0px'}} >
                                        <div>
                                            <div className="row">
                                                <div className="columns">
                                                    <div className={"row"}>
                                                        <div className={"columns small-5"}>
                                                            <div className="inputHeader">Genres</div>
                                                            <Select
                                                                value={this.state.filter.state}
                                                                onChange={(val)=>{
                                                                    this.changeFilter("state",val);
                                                                }}
                                                                options={[
                                                                    { value: '0', label: 'All' },
                                                                    { value: 'chocolate', label: 'Pop' },
                                                                    { value: 'strawberry', label: 'Jazz' },
                                                                    { value: 'vanilla', label: 'Latin' }
                                                                ]}
                                                            />
                                                        </div>
                                                        <div className={"columns small-6"}>
                                                            <div className="inputHeader">Years</div>
                                                            <Select
                                                                value={this.state.filter.session}
                                                                onChange={(val)=>{
                                                                    this.changeFilter("session",val);
                                                                }}
                                                                options={[
                                                                    { value: '0', label: 'All times' },
                                                                    { value: '1', label: '2019-2020' },
                                                                    { value: 'chocolate', label: '2018-2019' },
                                                                    { value: 'strawberry', label: '2017-2018' },
                                                                    { value: 'vanilla', label: '2016-2017' }
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="columns shrink">
                                                    <div className="inputHeader">Date Range</div>
                                                    <DateRangePicker onChange={(ranges)=>{
                                                        this.setState({
                                                            dateTimeRange:ranges
                                                        });

                                                    }} ranges={this.state.dateTimeRange}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Sticky>
                            <div className={"columns small-12 block marginTop"}>
                                <div className="summary">
                                    <div className={"row"}>
                                        <div className="columns shrink insight">
                                            <div>
                                                <div className={"value"}>
                                                    <div className={"row collapse"}>
                                                        <div className="columns shrink">
                                                            <span className={"number"}>0</span>
                                                        </div>
                                                        <div className="columns shrink">
                                                            <FontAwesomeIcon icon={faChartLine} size="1x"/>
                                                        </div>
                                                        <div className="columns shrink">
                                                            <span className={"percent"}>0%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"result"}>
                                                    <div className={"row collapse"}>
                                                        <div className={"columns"}>
                                                            <span className={"text"}>Added</span>
                                                        </div>
                                                        <div className={"columns shrink"}>
                                                            <TipBox attachment={"bottom center"} element={()=>{
                                                                return(
                                                                    <FontAwesomeIcon icon={faInfoCircle} size="xs"/>
                                                                )
                                                            }} content={()=>{
                                                                return(
                                                                    <p className={"tipBoxWrapper small"}>
                                                                        <FormattedMessage
                                                                            id="panel.xxx"
                                                                        />
                                                                    </p>
                                                                )
                                                            }}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="columns shrink insight">
                                            <div>
                                                <div className={"value positive"}>
                                                    <div className={"row collapse"}>
                                                        <div className="columns shrink">
                                                            <span className={"number"}>0</span>
                                                        </div>
                                                        <div className="columns shrink">
                                                            <FontAwesomeIcon icon={faChartLineDown} size="1x"/>
                                                        </div>
                                                        <div className="columns shrink">
                                                            <span className={"percent"}>0%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"result"}>
                                                    <div className={"row collapse"}>
                                                        <div className={"columns"}>
                                                            <span className={"text"}>Orders</span>
                                                        </div>
                                                        <div className={"columns shrink"}>
                                                            <TipBox attachment={"bottom center"} element={()=>{
                                                                return(
                                                                    <FontAwesomeIcon icon={faInfoCircle} size="xs"/>
                                                                )
                                                            }} content={()=>{
                                                                return(
                                                                    <p className={"tipBoxWrapper small"}>
                                                                        <FormattedMessage
                                                                            id="panel.xxx"
                                                                        />
                                                                    </p>
                                                                )
                                                            }}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="columns shrink insight">
                                            <div>
                                                <div className={"value positive"}>
                                                    <div className={"row collapse"}>
                                                        <div className="columns shrink">
                                                            <span className={"number"}>0</span>
                                                        </div>
                                                        <div className="columns shrink">
                                                            <FontAwesomeIcon icon={faChartLineDown} size="1x"/>
                                                        </div>
                                                        <div className="columns shrink">
                                                            <span className={"percent"}>0%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"result"}>
                                                    <div className={"row collapse"}>
                                                        <div className={"columns"}>
                                                            <span className={"text"}>Special Requst</span>
                                                        </div>
                                                        <div className={"columns shrink"}>
                                                            <TipBox attachment={"bottom center"} element={()=>{
                                                                return(
                                                                    <FontAwesomeIcon icon={faInfoCircle} size="xs"/>
                                                                )
                                                            }} content={()=>{
                                                                return(
                                                                    <p className={"tipBoxWrapper small"}>
                                                                        <FormattedMessage
                                                                            id="panel.xxx"
                                                                        />
                                                                    </p>
                                                                )
                                                            }}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="columns shrink insight">
                                            <div>
                                                <div className={"value"}>
                                                    <div className={"row collapse"}>
                                                        <div className="columns shrink">
                                                            <span className={"number"}>0</span>
                                                        </div>
                                                        <div className="columns shrink">
                                                            <FontAwesomeIcon icon={faChartLine} size="1x"/>
                                                        </div>
                                                        <div className="columns shrink">
                                                            <span className={"percent"}>0%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"result"}>
                                                    <div className={"row collapse"}>
                                                        <div className={"columns"}>
                                                            <span className={"text"}>Refunded</span>
                                                        </div>
                                                        <div className={"columns shrink"}>
                                                            <TipBox attachment={"bottom center"} element={()=>{
                                                                return(
                                                                    <FontAwesomeIcon icon={faInfoCircle} size="xs"/>
                                                                )
                                                            }} content={()=>{
                                                                return(
                                                                    <p className={"tipBoxWrapper small"}>
                                                                        <FormattedMessage
                                                                            id="panel.xxx"
                                                                        />
                                                                    </p>
                                                                )
                                                            }}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="columns shrink insight">
                                            <div>
                                                <div className={"value positive"}>
                                                    <div className={"row collapse"}>
                                                        <div className="columns shrink">
                                                            <span className={"number"}>0</span>
                                                        </div>
                                                        <div className="columns shrink">
                                                            <FontAwesomeIcon icon={faChartLineDown} size="1x"/>
                                                        </div>
                                                        <div className="columns shrink">
                                                            <span className={"percent"}>0%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"result"}>
                                                    <div className={"row collapse"}>
                                                        <div className={"columns"}>
                                                            <span className={"text"}>Removed</span>
                                                        </div>
                                                        <div className={"columns shrink"}>
                                                            <TipBox attachment={"bottom center"} element={()=>{
                                                                return(
                                                                    <FontAwesomeIcon icon={faInfoCircle} size="xs"/>
                                                                )
                                                            }} content={()=>{
                                                                return(
                                                                    <p className={"tipBoxWrapper small"}>
                                                                        <FormattedMessage
                                                                            id="panel.xxx"
                                                                        />
                                                                    </p>
                                                                )
                                                            }}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id={"tabs"} className={"columns small-12"}>
                                <ul className={"row collapse"}>
                                    <li className={"columns shrink"}>
                                        <Link to={"/analytics/summary/"} replace className={(this.props.url[2] == "summary" || this.props.url[2] == ""?"active":"")}>General</Link>
                                    </li>
                                    <li className={"columns shrink"}>
                                        <Link to={"/analytics/actions/"} replace className={(this.props.url[2] == "actions"?"active":"")}>Actions</Link>
                                    </li>
                                    <li className={"columns shrink"}>
                                        <Link to={"/analytics/members/"} replace className={(this.props.url[2] == "members"?"active":"")}>Music</Link>
                                    </li>
                                    <div className={"columns"}>
                                        <div className={"row collapse align-right"}>
                                            <div className={"columns shrink"}>
                                                <ReactToPrint
                                                    trigger={() => <div className={"button-icon"}><FontAwesomeIcon icon={faPrint} size="1x"/></div>}
                                                    content={() => this.printableArea}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                            <div className={"columns small-12 block marginTop"}>
                                <div className="chart big"  ref={el => (this.printableArea = el)}>
                                    <HighchartsChart plotOptions={{
                                        line:{

                                            marker: {
                                                lineWidth: 1
                                            },
                                            tooltip:{
                                                crosshairs:true
                                            },
                                        }
                                    }} >
                                        <Chart height={400} backgroundColor={"transparent"} />

                                        <Legend />

                                        <Tooltip
                                            crosshairs={true}
                                            shared={true}
                                        />

                                        <XAxis id="myXaxis" tickInterval={4}>
                                            <XAxis.Title>Days</XAxis.Title>
                                        </XAxis>

                                        <YAxis id="myYaxis">
                                            <YAxis.Title margin={20} marginLeft={0}>İşlem sayısı</YAxis.Title>
                                            <SplineSeries color={"rgba(61, 145, 255)"} fillColor={"rgba(61, 145, 255,0.8)"} name="Selected date" data={this.state.data1} />
                                            <SplineSeries color={"rgba(62, 207, 142)"} fillColor={"rgba(62, 207, 142, 0.8)"} name="Compored date" data={this.state.data2} />
                                        </YAxis>
                                    </HighchartsChart>
                                </div>
                            </div>
                        </div>
                    </div>
                </StickyContainer>
            </div>
        );
    }
}
export default withHighcharts(Analytics, Highcharts);
