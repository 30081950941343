import React, {useCallback} from 'react'
import '../../assets/css/pop.scss';

import { Checkbox,Radio } from 'pretty-checkbox-react';
import InputMask from 'react-input-mask';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faPencil, faTrash, faUsers} from "@fortawesome/pro-regular-svg-icons";
import Actions from "../../helpers/actions";
import Functions from "../../helpers/functions";
import Select from "react-select";
import 'react-tabs/style/react-tabs.css';
import Stepper from "react-stepper-horizontal";
import Avatar from "react-avatar";
import {AreaSeries, HighchartsSparkline, Tooltip} from "react-jsx-highcharts";
import Switch from "react-ios-switch/lib";
import ContentLoader from "react-content-loader";


const selectStyle = {
    option: (provided, state) => ({
        ...provided,
    }),
    control: (provided, state) => ({
        ...provided,
        position:'relative',
        zIndex:1005
    }),
}


export default class assignToUser extends React.Component {
    constructor(props) {
        super(props);
        this.defaultVars = {
            data:[
                {firstName:"Viktoriia",lastName:"MELNIK",assigned:true},
                {firstName:"Serdar",lastName:"BAKIR",assigned:true},
                {firstName:"Yusuf",lastName:"BESİM",assigned:true},
                {firstName:"Farabi Cin",lastName:"KARA",assigned:true},
            ]
        };

        this.state = this.defaultVars;
    }
    componentDidMount(){

    }
    async getUsers(){
        this.setState({loading:true});
        try {
            var url = Functions.generateURI(
                "/templates/categories",
                {

                }
            );

            var response = await fetch(url.address, url.header);
            response = await response.json();

            if(response.status){
                this.setState({users:response.data});
            } else {

            }
        } catch(error) {

        }
    }
    render(){

        return (
            <div id={"popForm"} className={"assignToUser"}>
                <div className={"row"}>
                    <div className={"columns small-12"}>
                        <ul className={"list"}>
                            {this.renderUserList()}
                        </ul>
                        <a className={"button full"} onClick={()=>{
                            this.props.closePopUp();
                        }}>CLOSE</a>
                    </div>
                </div>
            </div>
        );
    }
    renderUserList(){
        if(this.state.data.length > 0){
            return this.state.data.map((data,key)=>{
                return(
                    <li className={"row align-middle user"} key={"u_"+key}>
                        <div className={"columns name shrink"}>
                            <div className={"title"}>
                                <div className={"row collapse"}>
                                    <div className={"columns shrink"}>
                                        <Avatar color={"#5598d6"} name="L P G" src={"/assets/images/exampleCustomer.png"} size={35} round={true}/>
                                    </div>
                                    <div className={"columns"}>
                                        <a onClick={()=>{
                                            this.props.openSidePanel("users",3)
                                        }}><b>{data.firstName+" "+data.lastName}</b></a>
                                        <small>Lead Marketing</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"columns"}>
                            <div className={"row align-right"}>
                                <div className={"columns shrink"}>
                                    <Switch
                                        checked={data.assigned}
                                        className={"switch small"}
                                        handleColor="white"
                                        offColor="white"
                                        onChange={(checked) => {
                                            var newData = this.state.data;
                                            newData[key].assigned = checked;
                                            this.setState({data:newData});
                                        }}
                                        onColor="rgb(102, 170, 234)"
                                    />
                                </div>
                            </div>
                        </div>
                    </li>
                );
            });
        } else {
            var shimmers = [{},{},{},{},{},{},{},{},{},{}];
            return shimmers.map((data,i)=>{
                return(
                    <li className={"row shimmer"} key={"u_"+i}>
                        <div className={"columns small-12"}>
                            <ContentLoader
                                width={620}
                                height={50}
                            >
                                <rect x="0" y="0" rx="25" ry="25" width="50" height="50" />
                                <rect x="60" y="7" rx="4" ry="4" width="240" height="16" />
                                <rect x="60" y="30" rx="4" ry="4" width="240" height="10" />
                                <rect x="350" y="7" rx="4" ry="4" width="300" height="16" />
                                <rect x="350" y="30" rx="4" ry="4" width="300" height="10" />
                            </ContentLoader>
                        </div>
                    </li>
                );
            })
        }
    }
}

