import React from 'react';
import {Link } from "react-router-dom";
import Actions from '../../helpers/actions';
import Functions from '../../helpers/functions';
import Locale from '../../assets/languages/locale.json';

import TipBox from '../../components/tipBox';

import {FormattedMessage,FormattedNumber,FormattedDate,FormattedTime,FormattedRelativeTime} from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faLifeRing, faChevronRight,faChevronLeft, faEnvelope, faInfoCircle, faSignOutAlt,faFileExport,faFileDownload,faFilePdf} from '@fortawesome/pro-regular-svg-icons'
import { StickyContainer, Sticky } from 'react-sticky';
import Stepper from 'react-stepper-horizontal';
import CsvDownloader from 'react-csv-downloader';
import ReactPaginate from 'react-paginate';

import Select from 'react-select';
import {Line} from "rc-progress";
import Highcharts from 'highcharts';
import {
    HighchartsChart,
    Chart,
    withHighcharts,
    XAxis,
    YAxis,
    Tooltip,
    Title,
    Subtitle,
    Legend,
    LineSeries,
    SplineSeries,
    PieSeries
} from 'react-jsx-highcharts';
import Avatar from "react-avatar";
import ContentLoader from "react-content-loader";


const selectStyle = {
    option: (provided, state) => ({
        ...provided,
    }),
    control: (provided, state) => ({
        ...provided,
        position:'relative',
        zIndex:1005
    }),
}


class Orders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter:{
                state:{ value: '0', label: 'All' },
                status:{ value: '0', label: 'All' },
                order:{ value: 'time_az', label: 'Steps (Start to End)' }
            },
            data:[]
        };
        Highcharts.setOptions(Locale.tr.dateTime);
        Actions.register("closeSidePanel",this.closeSidePanel.bind(this));
    }
    componentDidMount(){
        this.getProjects();
    }
    closeSidePanel(){
        this.getProjects();
    }
    async getProjects(){
        this.setState({loading:true});
        try {
            var url = Functions.generateURI("/projects/get",{});

            var response = await fetch(url.address, url.header);
            response = await response.json();

            if(response.status){
                this.setState({data:response.data},()=>{

                });
            } else {

            }
        } catch(error) {

        }
    }
    changeFilter(target,value){
        var filter = this.state.filter;
        filter[target] = value;
        this.setState({filter:filter},()=>{

        });
        return false;
    }
    render(){
        return (
            <div id={"projects"}>
                <StickyContainer>
                    <div className={"row"}>
                        <div className={"columns small medium-12"}>
                            <Sticky topOffset={-38}>

                                {({
                                      isSticky,
                                      style,
                                  }) => (
                                    <div id={"filter"} className={"columns small-12 block noMargin"} style={{...style, marginTop: isSticky ? '38px' : '0px'}} >
                                        <div>
                                            <div className="row">
                                                <div className="columns small-2">
                                                    <div className="inputHeader">Customers</div>
                                                    <Select
                                                        styles={selectStyle}
                                                        value={this.state.filter.state}
                                                        onChange={(val)=>{
                                                            this.changeFilter("state",val);
                                                        }}
                                                        options={[
                                                            { value: '0', label: 'All' },
                                                            { value: 'chocolate', label: 'Jonathan Cobaa' },
                                                            { value: 'strawberry', label: 'John Doe' },
                                                            { value: 'vanilla', label: 'Mehmet Turgay' }
                                                        ]}
                                                    />
                                                </div>
                                                <div className="columns small-2">
                                                    <div className="inputHeader">Type</div>
                                                    <Select
                                                        styles={selectStyle}
                                                        value={this.state.filter.status}
                                                        onChange={(val)=>{
                                                            this.changeFilter("status",val);
                                                        }}
                                                        options={[
                                                            { value: '0', label: 'All' },
                                                            { value: 'chocolate', label: 'Create Music Library' },
                                                            { value: 'strawberry', label: 'Special Request' },
                                                            { value: 'vanilla', label: 'Completed Request' },
                                                            { value: 'vanilla', label: 'Payment Complete' }
                                                        ]}
                                                    />
                                                </div>
                                                <div className="columns">
                                                    <div className={"row align-right"}>
                                                        <div className="columns small-4">
                                                            <div className="inputHeader">Search</div>
                                                            <input type={"text"} placeholder={"Enter customer name or relation keywords.."}/>
                                                        </div>
                                                        <div className="columns small-4">
                                                            <div className="inputHeader">Sıralama</div>
                                                            <Select
                                                                styles={selectStyle}
                                                                value={this.state.filter.order}
                                                                onChange={(val)=>{
                                                                    this.changeFilter("order",val);
                                                                }}
                                                                options={[
                                                                    { value: 'time_az', label: 'Progress (Start-End)' },
                                                                    { value: 'time_za', label: 'Progress (End-Start)' },
                                                                    { value: 'name_az', label: 'Customer name A-Z' },
                                                                    { value: 'name_za', label: 'Customer name Z-A' },
                                                                ]}
                                                            />
                                                        </div>
                                                        <div className={"column shrink noPaddingLeft"}>
                                                            <CsvDownloader
                                                                filename="uyeler"
                                                                separator=";"
                                                                columns={[{
                                                                    id: 'first',
                                                                    displayName: 'First column'
                                                                }, {
                                                                    id: 'second',
                                                                    displayName: 'Second column'
                                                                }]}
                                                                datas={[{
                                                                    first: 'foo',
                                                                    second: 'bar'
                                                                }, {
                                                                    first: 'foobar',
                                                                    second: 'foobar'
                                                                }]}>
                                                                <div className={"export"}><FontAwesomeIcon icon={faFileDownload} size="1x"/></div>
                                                            </CsvDownloader>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Sticky>
                            <div className={"columns small-12 block marginTop"}>
                                <div className="list">
                                    <div className={"header row"}>
                                        <div className={"columns shrink"}>
                                            <div className={"title"}><b>Customer</b></div>
                                        </div>
                                        <div className={"columns shrink"}>
                                            <div className={"projectName"}><b>Status</b></div>
                                        </div>
                                        <div className={"columns shrink"}>
                                            <div className={"projectSteps"}><b>Steps</b></div>
                                        </div>
                                        <div className={"columns"}>
                                            &nbsp;
                                        </div>
                                    </div>
                                    <div className={"container"}>
                                        <ul>
                                            {this.renderItemList()}
                                        </ul>
                                        <div className={"pagination"}>
                                            <ReactPaginate
                                                previousLabel={<FontAwesomeIcon icon={faChevronLeft} size="lg"/>}
                                                nextLabel={<FontAwesomeIcon icon={faChevronRight} size="lg"/>}
                                                breakLabel={'...'}
                                                breakClassName={'break-me'}
                                                pageCount={22}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={()=>{
                                                    Functions.scrollTo(0);
                                                }}
                                                containerClassName={'pagination'}
                                                subContainerClassName={'pages pagination'}
                                                activeClassName={'active'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </StickyContainer>
            </div>
        );
    }
    async convertOrder(id,rid){
        this.setState({loading:true});
        try {
            var url = Functions.generateURI("/projects/convertToOrder",{
                id:id,
                rid:rid,
                method:"POST"
            });

            var response = await fetch(url.address, url.header);
            response = await response.json();

            if(response.status){
                this.getProjects();
            } else {

            }
        } catch(error) {

        }
    }
    renderItemList(){
        if(this.state.data.length > 0){
            return this.state.data.map((data,key)=>{
                var currentlyStep = 0;
                switch(data.lastStatus){
                    case "InitialOffer":
                        currentlyStep = 0;
                        break;
                    case "Order":
                        currentlyStep = 1;
                        break;
                    case "WorkOrder":
                        currentlyStep = 2;
                        break;
                }

                return(
                    <li className={"row align-middle user"} key={"u_"+key}>
                        <div className={"columns name shrink"}>
                            <div className={"title"}>
                                <div className={"row collapse"}>
                                    <div className={"columns shrink"}>
                                        <Avatar color={"#5598d6"} name="L P G" src={"/assets/images/exampleCustomer.png"} size={35} round={true}/>
                                    </div>
                                    <div className={"columns"}>
                                        <a onClick={()=>{
                                            //this.props.openSidePanel("project",data.id)
                                        }}><b>Joseph Turner</b></a>
                                        <small>yusuf@artperpixel.com</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"columns name projectName shrink"}>
                            <div className={"title"}>
                                <a onClick={()=>{

                                }}><b>User action message here</b></a>
                                <small>Last update: <FormattedRelativeTime value={((new Date(data.creationDate).getTime() - new Date().getTime()) / 1000)} numeric="auto" updateIntervalInSeconds={10} /></small>
                            </div>
                        </div>
                        <div className={"columns small-4 name stepper noPaddingRight"}>
                            <Stepper titleTop={4} circleTop={0} size={22} circleFontSize={12} defaultBorderStyle={{lineHeight:0}} steps={ [{title:"Created Music List"}, {title:"Special Request"}, {title:"Completed Request" }, {title:"Payment Completed"}] } titleFontSize={12} defaultTitleColor={"#666"} activeTitleColor={"#4085c7"} completeTitleColor={"#4085c7"} activeBorderColor={"#666"} defaultBarColor={"#CCC"}
                                     activeStep={currentlyStep} />
                        </div>
                        <div className={"columns"}>
                            <div className="actions">
                                <div className="row collapse align-right">
                                    {this.renderStatusActions(data)}
                                </div>
                            </div>
                        </div>
                    </li>
                );
            });
        } else {
            var shimmers = [{},{},{},{},{},{},{},{},{},{}];
            return shimmers.map((data,i)=>{
                return(
                    <li className={"row shimmer"} key={"u_"+i}>
                        <div className={"columns small-12"}>
                            <ContentLoader
                                width={1350}
                                height={50}
                            >
                                <rect x="0" y="0" rx="25" ry="25" width="50" height="50" />
                                <rect x="60" y="7" rx="4" ry="4" width="240" height="16" />
                                <rect x="60" y="30" rx="4" ry="4" width="240" height="10" />
                                <rect x="350" y="7" rx="4" ry="4" width="300" height="16" />
                                <rect x="350" y="30" rx="4" ry="4" width="300" height="10" />
                                <rect x="786" y="0" rx="25" ry="25" width="50" height="50" />
                                <rect x="856" y="0" rx="25" ry="25" width="50" height="50" />
                                <rect x="926" y="0" rx="25" ry="25" width="50" height="50" />
                                <rect x="996" y="0" rx="25" ry="25" width="50" height="50" />
                                <rect x="1066" y="0" rx="25" ry="25" width="50" height="50" />
                                <rect x="1170" y="0" rx="25" ry="25" width="50" height="50" />
                            </ContentLoader>
                        </div>
                    </li>
                );
            })
        }
    }
    renderStatusActions(data){
        switch(data.lastStatus){
            case "InitialOffe1r":

                break;
            default:
                return(
                    <div>
                        <a className="action columns shrink button"
                           style={{marginRight:10,position:"relative",fontSize:15,paddingTop:7,paddingBottom:7}}
                           onClick={()=>{
                               //this.convertOrder(data.id, data.responsible.id);
                           }}
                        >
                            Show detail
                        </a>
                    </div>
                );
                break;
        }
    }
}
export class OrdersMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    componentDidMount(){

    }
    render(){
        return (
            <div>

            </div>
        );
    }
}

export default withHighcharts(Orders, Highcharts);
