import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import IntlTelInput from 'react-intl-tel-input';

import Functions from '../../helpers/functions';

import 'react-intl-tel-input/dist/main.css';
import '../../assets/css/auth.scss';

import {FormattedMessage, FormattedNumber} from "react-intl";
import { ClassicSpinner } from "react-spinners-kit";
import { Checkbox,Radio } from 'pretty-checkbox-react';

import PinInput from "react-pin-input";
import TipBox from '../../components/tipBox';
import { Transition } from 'react-transition-group';
import Actions from "../../helpers/actions";

const duration = 200;

const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
}

const transitionStyles = {
    entering: { opacity: 1 },
    entered:  { opacity: 1 },
    exiting:  { opacity: 0 },
    exited:  { opacity: 0 },
};

export default class Auth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step:1,
            pin:"",
            phone:"",
            time:180,
            email:"",
            password:"",
            screenShow:true,
            loading:false,
            aggreementAccepted:false,
            inputIsValid:false
        };
        this.timeControl = false;
        this.form = false;
    }
    startTime(){
        var thiz = this;
        clearInterval(thiz.timeControl);
        this.setState({time:180},()=>{
            thiz.timeControl = setInterval(()=>{
                if(thiz.state.time > 0){
                    thiz.setState({time:(thiz.state.time - 1)})
                } else {
                    thiz.setState({time:0},()=>{
                        clearInterval(thiz.timeControl);
                    })
                }
            },1000);
        });
    }
    goStep(step,fn){
        fn = (typeof fn == "undefined"?()=>{}:fn);
        this.setState({screenShow:false},()=>{
            setTimeout(()=>{
                this.setState({step:step},()=>{
                    setTimeout(()=>{
                        this.setState({screenShow:true,loading:false},()=>{
                            fn();
                        });
                    },duration);
                });
            },duration);
        });
    }
    async checkPin(){
        this.setState({loading:true});
        try {
            var url = Functions.generateURI(
                "/user/checkPass",
                {
                    pin:this.state.pin,
                    uid:this.state.uid

                }
            );

            var response = await fetch(url.address, url.header);
            response = await response.json();

            if(response.status){
                this.props.userAction("login",response.data.user);
            } else {
                alert("Your password not match with this account");
            }
        } catch(error) {
            alert("Something gone wrong. Please try again later");
        }
    }
    async sendPinCode(){
        if(this.state.inputIsValid && !this.state.loading && this.state.aggreementAccepted){
            this.setState({loading:true});
            try {
                var url = Functions.generateURI(
                    "/user/generatePIN",
                    {
                        phone:this.state.phone,
                        gmt:(new Date().getTimezoneOffset() / 60)

                    }
                );

                var response = await fetch(url.address, url.header);
                response = await response.json();

                if(response.status){
                    this.setState({
                        pinExpired:response.data.pinExpired,
                        uid:response.data.uid
                    },()=>{
                        this.goStep(2,()=>{this.startTime();});
                    });
                } else {
                    this.setState({loading:false});
                    alert("This email is not registered Audiolinn");
                }
            } catch(error) {
                this.setState({loading:false});
                alert("Something gone wrong, try again later...");
            }
        }
    }
    render(){
        return (
            <Router>
                <div id={"auth"} className={"bg"}>
                    <div>
                        <div className={"row align-middle align-center"}>
                            <div className={"columns shrink"}>
                                <img src={"/assets/images/logo.png"} className={"logo"} />
                                <Transition in={this.state.screenShow} timeout={duration}>
                                    {state => (
                                        <div style={{
                                            ...defaultStyle,
                                            ...transitionStyles[state]
                                        }}>
                                            {this.renderLoginView()}
                                        </div>
                                    )}
                                </Transition>
                            </div>
                        </div>
                    </div>
                </div>
            </Router>
        );
    }
    checkEmailAndPassword(){
        var inputIsValid = false;
        if(Functions.validateEmail(this.state.email) && this.state.password != ""){
            inputIsValid = true;
        } else {
            inputIsValid = false;
        }
        this.setState({inputIsValid:inputIsValid});
    }
    renderLoginView(){
        switch(this.state.step){
            case 2:
                var Message = false;
                if (this.state.time == 0) {
                    Message = <div>
                        For change your password
                        <a onClick={()=>{
                            this.goStep(1);
                            return false;
                        }}> click</a>
                    </div>;
                } else {
                    Message = <div>
                        Time {this.state.time} left
                    </div>;
                }

                return(
                    <form method="post" onSubmit={(e)=>{
                        this.checkPin()
                        e.preventDefault();
                        return false;
                    }}>
                        <div className={"row align-center"}>
                            <div className={"columns small-11 medium-10 large-10"}>
                                <div className={"row align-center"}>
                                    <div className={"columns small-12 medium-7 large-7"}>
                                        <div className={"row"}>
                                            <div className={"columns small-12"}>
                                                <p className={"infoText"}>
                                                    Please enter your password for login creator panel
                                                </p>
                                            </div>
                                            <div className={"columns small-12"}>
                                                <PinInput
                                                    focus={true}
                                                    length={6}
                                                    initialValue=""
                                                    onChange={(value, index) => {

                                                    }}
                                                    type="numeric"
                                                    style={{padding: '10px'}}
                                                    inputStyle={{borderColor: '#CCC',backgroundColor:"#FFF",margin:"0px 4px",fontSize:"22px",fontWeight: 700,color:"#666",borderRadius: "6px"}}
                                                    inputFocusStyle={{boxShadow:"0px 0px 0px 1px rgba(62, 171, 206, 0.52)" }}
                                                    onComplete={(value, index) => {
                                                        this.setState({pin:value},()=>{
                                                            this.checkPin()
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className={"columns small-12"}>
                                                <p className={"infoText alt"}>
                                                    {Message}
                                                </p>
                                            </div>
                                            <div className={"columns small-12"}>
                                                <TipBox isOpen={this.state.toolTipOpen} attachment={"top center"} element={()=>{
                                                    return(
                                                        <a onClick={()=>{
                                                            this.goStep(1);
                                                            return false;
                                                        }}>
                                                            Go back to e-mail input
                                                        </a>
                                                    )
                                                }} content={()=>{
                                                    return(
                                                        <p className={"tipBoxWrapper"}>
                                                            If you think you entered wrong e-mail account. Click for go back to e-mail input screen!
                                                        </p>
                                                    )
                                                }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                );
                break;
            case 3:
                break;
            default:
                return(
                    <form ref={(ref)=>{this.form = ref}} method="post" onSubmit={(e)=>{
                        this.sendPinCode();
                        e.preventDefault();
                        return false;
                    }}>
                        <div className={"row align-center"}>
                            <div className={"columns small-11 medium-10 large-10"}>
                                <div className={"row align-center"}>
                                    <div className={"columns small-12 medium-5 large-5"}>
                                        <div className={"row"}>
                                            <div className={"columns small-12"}>
                                                <p className={"infoText"}>
                                                    Enter your e-mail address for access panel
                                                </p>
                                            </div>
                                            <div className={"columns small-12"}>
                                                <div style={{padding: '10px',position:'relative'}}>
                                                    <input type={"text"} value={this.state.phone} className={"textInput"} style={{textAlign:'center'}} onChange={(e) => {
                                                        this.setState({inputIsValid:true,phone:e.target.value});
                                                    }} />
                                                    <span className={"inputSpinner"}>
                                                        <ClassicSpinner
                                                            size={19}
                                                            color="#2ba6cb"
                                                            loading={this.state.loading}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className={"columns small-12"}>
                                                <p className={"infoText alt"}>

                                                </p>
                                            </div>
                                            <div className={"columns small-12"}>
                                                <p className={"aggreement alt"} style={{}}>
                                                    <Checkbox name="aggreement" color="primary-o" animation="smooth" value={1}  checked={(this.state.aggreementAccepted?true:false)} onChange={(e) => {
                                                        this.setState({aggreementAccepted:!this.state.aggreementAccepted})
                                                    }}>Readed <a href="https://dahataze.com/" target="_blank" onClick={()=>{
                                                        window.open("https://daahataze.com/stores/",'_blank');
                                                    }} style={{fontSize:16}}>Terms & Conditions</a> and accepting</Checkbox>
                                                </p>
                                            </div>
                                            <div className={"columns small-12"}>
                                                <div className="buttons">
                                                    <a className={"login transition button"+((this.state.loading || !this.state.inputIsValid) || !this.state.aggreementAccepted?" disabled":"")} onClick={this.sendPinCode.bind(this)}>
                                                        <FormattedMessage
                                                            id={this.state.loading?"auth.signingInTitle":"auth.signInTitle"}
                                                        />{this.state.loading?"...":""}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                );
                break;
        }
    }
}
