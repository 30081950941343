import React, {useCallback} from 'react'
import '../../assets/css/products.scss';
import InputMask from 'react-input-mask';
import { StickyContainer, Sticky } from 'react-sticky';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ScrollLock, { TouchScrollable } from 'react-scrolllock';
import {
    faBallotCheck,
    faBoxCheck,
    faChevronLeft,
    faChevronRight,
    faClock,
    faEnvelope,
    faPhone,
    faTimesCircle
} from "@fortawesome/pro-regular-svg-icons";
import Actions from "../../helpers/actions";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import InputRange from 'react-input-range';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Functions from "../../helpers/functions";
import ContentLoader from "react-content-loader";
import NumericInput from 'react-numeric-input';
import { Checkbox,Radio } from 'pretty-checkbox-react';
import Button from "../../components/button";

import Locale from '../../assets/languages/locale.json';
import Highcharts from 'highcharts';
import addHighchartsMore from 'highcharts/highcharts-more';
import {
    HighchartsChart, Chart, withHighcharts, XAxis, YAxis, Tooltip, Title, Subtitle, Legend,ColumnSeries,PieSeries, LineSeries, SplineSeries,ColumnRangeSeries, AreaSeries,AreaSplineSeries, Annotation
} from 'react-jsx-highcharts';
import ReactToPrint from "react-to-print";
import {Line} from "rc-progress";
import {FormattedDate, FormattedMessage, FormattedRelativeTime} from "react-intl";
import TipBox from "../tipBox";
import ReactPaginate from "react-paginate";
import Moment from 'moment/min/moment-with-locales';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import 'moment/locale/tr';

require("highcharts/modules/annotations")(Highcharts);
addHighchartsMore(Highcharts);


const selectStyle = {
    option: (provided, state) => ({
        ...provided,
    }),
    control: (provided, state) => ({
        ...provided,
        position:'relative',
        zIndex:1005
    }),
}



const hours = [
    "05:00", "05:30",
    "06:00", "06:30",
    "07:00", "07:30",
    "08:00", "08:30",
    "09:00", "09:30",
    "10:00", "10:30",
    "11:00", "11:30",
    "12:00", "12:30",
    "13:00", "13:30",
    "14:00", "14:30",
    "15:00", "15:30",
    "16:00", "16:30",
    "17:00", "17:30",
    "18:00", "18:30",
    "19:00", "19:30",
    "20:00", "20:30",
    "21:00", "21:30",
    "22:00", "22:30",
    "23:00", "23:30"
];

class newProduct extends React.Component {
    constructor(props) {
        super(props);

        var data = {
            id:0,
            containerHeight:300,
            contentAvaliable:false,
            tabIndex:0,
            name:"",
            description:"",
            bpm:0,
            duration:0,
            date:{id:0,label:"Bugün",value:Moment().format("MM/DD/YYYY")},
            startTime:"",
            endTime:"",
            isSuprise:false,
            qty:1,
            oldPrice:10,
            discount:30,
            cid:[],
            categories:[
                {id:0,label:"YÜKLENİYOR",value:",0,1,"},
            ],
            instruments:[
                {id:0,label:"YÜKLENİYOR",value:",0,1,"},
            ],
            instrumentList:[],
            tags:[
                {id:0,label:"YÜKLENİYOR",value:",0,1,"},
            ],
            tagList:[],
            moods:[
                {id:0,label:"YÜKLENİYOR",value:",0,1,"},
            ],
            moodList:[],
            images:[],
            image:0,
            isUpdate:false,
            donatedQty:0,
            preview:{
                version15:{},
            },
            original:{
                version15:{},
            }
        };
        if(typeof this.props.data.id != "undefined"){
            data.isUpdate = (typeof this.props.data.isUpdate != "undefined"?this.props.data.isUpdate:false);
            data.id = this.props.data.id;
            data.cid = {label:this.props.data.cat_name,value:this.props.data.cid};
            data.cid2 = {label:this.props.data.cat_name,value:this.props.data.cid};
            data.cid3 = {label:this.props.data.cat_name,value:this.props.data.cid};
            data.name = this.props.data.name;
            data.bpm = this.props.data.bpm;
            data.duration = this.props.data.duration;
            data.description = this.props.data.description;
            data.qty = this.props.data.stock;
            data.oldPrice = this.props.data.priceEUR;
            data.discount = this.props.data.autoExchangeDiscountPercent;
            data.preview = {
                version15:{}
            };
            data.original = {
                version15:{}
            };
            if(this.props.data.image > 0){
                data.image = this.props.data.image;
            }
        }

        this.state = data;
    }
    componentDidMount(){
        setTimeout(()=>{
            //this.setState({containerHeight:(document.getElementById('panelContent').clientHeight - 202)})
        })
        this.getCategories();
        if(typeof this.props.data.id != "undefined"){
            this.getProduct();
        } else {
            this.createProductID();
        }
    }
    async createProductID(){
        this.setState({loading:true});
        try {
            var url = Functions.generateURI("/products/createProductID",{method:"GET"});

            var response = await fetch(url.address, url.header);
            response = await response.json();
            if(response.status){
                console.log(response);
                this.setState({id:response.data.id,contentAvaliable:true},()=>{
                    this.getProduct();
                });
            } else {

            }
        } catch(error) {

        }
    }
    async getProduct(){
        this.setState({loading:true});
        try {
            var url = Functions.generateURI("/store/getProduct",{method:"GET",id:this.state.id});

            var response = await fetch(url.address, url.header);
            response = await response.json();
            if(response.status){
                var instruments = [];
                response.data.instruments.forEach((item)=>{
                    instruments.push({label:item.value,value:item.id});
                });
                var moods = [];
                var tags = [];
                var genres = [];
                if(response.data.specs != false){
                    response.data.specs.forEach((item)=>{
                        switch(item.sid){
                            case "1":
                                moods.push({label:item.value,value:item.vid});
                                break;
                            case "5":
                                tags.push({label:item.value,value:item.vid});
                                break;
                        }
                    });
                }
                response.data.genres.forEach((item)=>{
                    genres.push({label:item.name,value:item.cid+item.id+","});
                });
                this.setState({
                    contentAvaliable:true,
                    name:response.data.name,
                    description:response.data.description,
                    bpm:response.data.bpm,
                    instrumentList:instruments,
                    moodList:moods,
                    tagList:tags,
                    cid:genres
                });
            } else {

            }
        } catch(error) {

        }
    }
    async getCategories(){
        this.setState({loading:true});
        try {
            var url = Functions.generateURI("/store/getSelectableCategories",{method:"GET",id:this.state.id});

            var response = await fetch(url.address, url.header);
            response = await response.json();
            if(response.status){
                this.setState({categories:response.data},()=>{
                    this.getFilters();
                });
            } else {

            }
        } catch(error) {

        }
    }
    async getFilters(){
        this.setState({loading:true});
        try {
            var url = Functions.generateURI("/store/getSelectableSpecs",{method:"GET",id:this.state.id});

            var response = await fetch(url.address, url.header);
            response = await response.json();
            if(response.status){
                this.setState({
                    instruments:response.data.instruments,
                    moods:response.data.moods,
                    tags:response.data.tags
                },()=>{

                });
            } else {

            }
        } catch(error) {

        }
    }
    componentWillReceiveProps(nextProps){

    }
    handleFileChange(e,target,type) {
        if (e.target.files) {
            this.state[target][type] = e.target.files[0];
            this.uploadFile(this.state.id,e.target.files,target,type);
        }
    };
    renderContent(){
        if(this.state.contentAvaliable){
            return(
                <Tabs selectedIndex={this.state.tabIndex} onSelect={(tabIndex) => {
                    this.setState({ tabIndex },()=>{
                        setTimeout(()=>{
                            //window.scrollTo(0, this.contentView.offsetTop)
                        },3000)
                    });
                }}>
                    <TabList>
                        <Tab>Detail</Tab>
                        <Tab>Files (Preview)</Tab>
                        <Tab>Files (Orginal)</Tab>
                    </TabList>
                    <ScrollLock>
                        <div className={"container"}  ref={(ref)=>{this.contentView = ref;}}>
                            <TabPanel>
                                <div style={{marginTop:10}}>
                                    <div>
                                        <div className={"field row collapse"}>
                                            <div className={"columns small-12 medium-12"}>
                                                <div className={"row title"}>
                                                    <div className={"columns"}>
                                                        Genres
                                                    </div>
                                                </div>
                                                <div className={"row input"}>
                                                    <div className={"columns"}>
                                                        <Select
                                                            styles={selectStyle}
                                                            defaultValue={this.state.cid}
                                                            isMulti
                                                            options={this.state.categories}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            onChange={(val)=>{
                                                                this.setState({"cid":val});
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"field row collapse"}>
                                            <div className={"columns small-12 medium-12"}>
                                                <div className={"row title"}>
                                                    <div className={"columns"}>
                                                        Tags
                                                    </div>
                                                </div>
                                                <div className={"row input"}>
                                                    <div className={"columns"}>
                                                        <Select
                                                            styles={selectStyle}
                                                            defaultValue={this.state.tagList}
                                                            isMulti
                                                            options={this.state.tags}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            onChange={(val)=>{
                                                                this.setState({"tagList":val});
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"field row collapse"}>
                                            <div className={"columns small-12 medium-12"}>
                                                <div className={"row title"}>
                                                    <div className={"columns"}>
                                                        Moods
                                                    </div>
                                                </div>
                                                <div className={"row input"}>
                                                    <div className={"columns"}>
                                                        <Select
                                                            styles={selectStyle}
                                                            defaultValue={this.state.moodList}
                                                            isMulti
                                                            options={this.state.moods}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            onChange={(val)=>{
                                                                this.setState({"moodList":val});
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"field row collapse"}>
                                            <div className={"columns small-12 medium-12"}>
                                                <div className={"row title"}>
                                                    <div className={"columns"}>
                                                        Instruments
                                                    </div>
                                                </div>
                                                <div className={"row input"}>
                                                    <div className={"columns"}>
                                                        <Select
                                                            styles={selectStyle}
                                                            defaultValue={this.state.instrumentList}
                                                            isMulti
                                                            options={this.state.instruments}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            onChange={(val)=>{
                                                                this.setState({"instrumentList":val});
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"field row collapse"}>
                                            <div className={"columns small-12 medium-12"}>
                                                <div className={"row title"}>
                                                    <div className={"columns"}>
                                                        Music name
                                                    </div>
                                                </div>
                                                <div className={"row input"}>
                                                    <div className={"columns"}>
                                                        <input type={"text"} value={this.state.name} className={"textInput"} onChange={(e) => {
                                                            this.setState({name:e.target.value});
                                                        }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"field row collapse"}>
                                            <div className={"columns small-12 medium-12"}>
                                                <div className={"row title"}>
                                                    <div className={"columns"}>
                                                        Music description
                                                    </div>
                                                </div>
                                                <div className={"row input"}>
                                                    <div className={"columns"}>
                                                        <input type={"text"} value={this.state.description} className={"textInput"} onChange={(e) => {
                                                            this.setState({description:e.target.value});
                                                        }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"field row collapse"}>
                                            <div className={"columns small-12 medium-12"}>
                                                <div className={"row title"}>
                                                    <div className={"columns"}>
                                                        Music details
                                                    </div>
                                                </div>
                                                <div className={"row input"}>
                                                    <div className={"columns small-4 medium-3"}>
                                                        <div className={"row title"}>
                                                            <div className={"columns"}>
                                                                BPM
                                                            </div>
                                                        </div>
                                                        <div className={"row input"}>
                                                            <div className={"columns"}>
                                                                <input type={"text"} value={this.state.bpm} className={"textInput"} onChange={(e) => {
                                                                    this.setState({bpm:e.target.value});
                                                                }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"columns small-4 medium-3"}>
                                                        <div className={"row title"}>
                                                            <div className={"columns"}>
                                                                Duration (second)
                                                            </div>
                                                        </div>
                                                        <div className={"row input"}>
                                                            <div className={"columns"}>
                                                                <input type={"text"} value={this.state.duration} className={"textInput"} onChange={(e) => {
                                                                    this.setState({duration:e.target.value});
                                                                }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"field row collapse"}>
                                            <div className={"columns small-12 medium-12"}>
                                                <div className={"row title"}>
                                                    <div className={"columns"}>
                                                        Publishing
                                                    </div>
                                                </div>
                                                <div className={"row input"}>
                                                    <div className={"columns small-12 medium-4"}>
                                                        <div className={"row"}>
                                                            <div className={"columns small-12 medium-12"}>
                                                                <div className={"row title"}>
                                                                    <div className={"columns"}>
                                                                        Date
                                                                    </div>
                                                                </div>
                                                                <div className={"row input"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <Select
                                                                            styles={selectStyle} isSearchable={false} isClearable={false}
                                                                            value={this.state.date}
                                                                            onChange={(val)=>{
                                                                                this.setState({"date":val,"startHour":{label:"Seçiniz",value:""}});
                                                                            }}
                                                                            options={
                                                                                [
                                                                                    {id:0,label:"Today",value:Moment().format("MM/DD/YYYY")},
                                                                                    {id:1,label:"Yesterday",value:Moment().add(1, 'days').format("MM/DD/YYYY")},
                                                                                ]
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"columns small-12 medium-4"}>
                                                        <div className={"row"}>
                                                            <div className={"columns small-12 medium-12"}>
                                                                <div className={"row title"}>
                                                                    <div className={"columns"}>
                                                                        Hour
                                                                    </div>
                                                                </div>
                                                                <div className={"row input"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <Select
                                                                            styles={selectStyle} isSearchable={false} isClearable={false}
                                                                            value={this.state.startTime}
                                                                            onChange={(val)=>{
                                                                                this.setState({"startTime":val});
                                                                            }}
                                                                            options={this.getStartHours()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"field row collapse"}>
                                            <div className={"columns small-12 medium-12"}>
                                                <div className={"row title"}>
                                                    <div className={"columns"}>
                                                        Licencing (USD)
                                                    </div>
                                                </div>
                                                <div className={"row input"}>
                                                    <div className={"columns small-12 medium-2"}>
                                                        <div className={"row"}>
                                                            <div className={"columns small-12 medium-12"}>
                                                                <div className={"row title"}>
                                                                    <div className={"columns"}>
                                                                        Standart
                                                                    </div>
                                                                </div>
                                                                <div className={"row input"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <NumericInput className={"textInput"} min={1} value={this.state.oldPrice} onChange={(value) => {
                                                                            var oldPrice = parseFloat(value);
                                                                            this.setState({oldPrice:oldPrice});
                                                                        }} onBlur={(e)=>{
                                                                            this.setState({oldPrice:e.target.value});
                                                                        }}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"columns small-12 medium-2"}>
                                                        <div className={"row"}>
                                                            <div className={"columns small-12 medium-12"}>
                                                                <div className={"row title"}>
                                                                    <div className={"columns"}>
                                                                        Social Media
                                                                    </div>
                                                                </div>
                                                                <div className={"row input"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <input type={"text"} value={0} disabled={true} className={"textInput"} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"columns small-12 medium-2"}>
                                                        <div className={"row"}>
                                                            <div className={"columns small-12 medium-12"}>
                                                                <div className={"row title"}>
                                                                    <div className={"columns"}>
                                                                        Game
                                                                    </div>
                                                                </div>
                                                                <div className={"row input"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <input type={"text"} value={0} disabled={true} className={"textInput"} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"columns small-12 medium-2"}>
                                                        <div className={"row"}>
                                                            <div className={"columns small-12 medium-12"}>
                                                                <div className={"row title"}>
                                                                    <div className={"columns"}>
                                                                        Premium
                                                                    </div>
                                                                </div>
                                                                <div className={"row input"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <input type={"text"} value={0} disabled={true} className={"textInput"} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div style={{marginTop:10}}>
                                    <div>
                                        <div className={"field row collapse"}>
                                            <div className={"columns small-12 medium-12"}>
                                                <div className={"row input"}>
                                                    <div className={"columns small-12 medium-12"}>
                                                        <div className={"row title"}>
                                                            <div className={"columns"}>
                                                                Versions
                                                            </div>
                                                        </div>
                                                        <div className={"row input"}>
                                                            <div className={"columns small-2"}>
                                                                <div className={"row"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <div className={"row title"}>
                                                                            <div className={"columns"}>
                                                                                15
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row input"}>
                                                                            <div className={"columns"}>
                                                                                <input type={"file"} onChange={(e)=>{
                                                                                    this.handleFileChange(e,"preview","version15")
                                                                                }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"columns small-2"}>
                                                                <div className={"row"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <div className={"row title"}>
                                                                            <div className={"columns"}>
                                                                                30
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row input"}>
                                                                            <div className={"columns"}>
                                                                                <input type={"file"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"columns small-2"}>
                                                                <div className={"row"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <div className={"row title"}>
                                                                            <div className={"columns"}>
                                                                                60
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row input"}>
                                                                            <div className={"columns"}>
                                                                                <input type={"file"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"columns small-2"}>
                                                                <div className={"row"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <div className={"row title"}>
                                                                            <div className={"columns"}>
                                                                                Full
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row input"}>
                                                                            <div className={"columns"}>
                                                                                <input type={"file"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"row input"}>
                                                    <div className={"columns small-12 medium-12"}>
                                                        <div className={"row title"}>
                                                            <div className={"columns"}>
                                                                Loops
                                                            </div>
                                                        </div>
                                                        <div className={"row input"}>
                                                            <div className={"columns small-2"}>
                                                                <div className={"row"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <div className={"row title"}>
                                                                            <div className={"columns"}>
                                                                                1
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row input"}>
                                                                            <div className={"columns"}>
                                                                                <input type={"file"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"columns small-2"}>
                                                                <div className={"row"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <div className={"row title"}>
                                                                            <div className={"columns"}>
                                                                                2
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row input"}>
                                                                            <div className={"columns"}>
                                                                                <input type={"file"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"columns small-2"}>
                                                                <div className={"row"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <div className={"row title"}>
                                                                            <div className={"columns"}>
                                                                                3
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row input"}>
                                                                            <div className={"columns"}>
                                                                                <input type={"file"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"columns small-2"}>
                                                                <div className={"row"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <div className={"row title"}>
                                                                            <div className={"columns"}>
                                                                                4
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row input"}>
                                                                            <div className={"columns"}>
                                                                                <input type={"file"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"columns small-2"}>
                                                                <div className={"row"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <div className={"row title"}>
                                                                            <div className={"columns"}>
                                                                                5
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row input"}>
                                                                            <div className={"columns"}>
                                                                                <input type={"file"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"columns small-2"}>
                                                                <div className={"row"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <div className={"row title"}>
                                                                            <div className={"columns"}>
                                                                                6
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row input"}>
                                                                            <div className={"columns"}>
                                                                                <input type={"file"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"columns small-2"}>
                                                                <div className={"row"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <div className={"row title"}>
                                                                            <div className={"columns"}>
                                                                                7
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row input"}>
                                                                            <div className={"columns"}>
                                                                                <input type={"file"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"columns small-2"}>
                                                                <div className={"row"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <div className={"row title"}>
                                                                            <div className={"columns"}>
                                                                                8
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row input"}>
                                                                            <div className={"columns"}>
                                                                                <input type={"file"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className={"row input"}>
                                                    <div className={"columns small-12 medium-12"}>
                                                        <div className={"row title"}>
                                                            <div className={"columns"}>
                                                                Stems
                                                            </div>
                                                        </div>
                                                        <div className={"row input"}>
                                                            <div className={"columns small-2"}>
                                                                <div className={"row"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <div className={"row title"}>
                                                                            <div className={"columns"}>
                                                                                Stem 1
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row input"}>
                                                                            <div className={"columns"}>
                                                                                <input type={"file"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"columns small-2"}>
                                                                <div className={"row"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <div className={"row title"}>
                                                                            <div className={"columns"}>
                                                                                Stem 2
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row input"}>
                                                                            <div className={"columns"}>
                                                                                <input type={"file"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"columns small-2"}>
                                                                <div className={"row"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <div className={"row title"}>
                                                                            <div className={"columns"}>
                                                                                Stem 3
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row input"}>
                                                                            <div className={"columns"}>
                                                                                <input type={"file"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"columns small-2"}>
                                                                <div className={"row"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <div className={"row title"}>
                                                                            <div className={"columns"}>
                                                                                Stem 4
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row input"}>
                                                                            <div className={"columns"}>
                                                                                <input type={"file"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"columns small-2"}>
                                                                <div className={"row"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <div className={"row title"}>
                                                                            <div className={"columns"}>
                                                                                Stem 5
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row input"}>
                                                                            <div className={"columns"}>
                                                                                <input type={"file"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"columns small-2"}>
                                                                <div className={"row"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <div className={"row title"}>
                                                                            <div className={"columns"}>
                                                                                Stem 6
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row input"}>
                                                                            <div className={"columns"}>
                                                                                <input type={"file"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"columns small-2"}>
                                                                <div className={"row"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <div className={"row title"}>
                                                                            <div className={"columns"}>
                                                                                Stem 7
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row input"}>
                                                                            <div className={"columns"}>
                                                                                <input type={"file"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div style={{marginTop:10}}>
                                    <div>
                                        <div className={"field row collapse"}>
                                            <div className={"columns small-12 medium-12"}>
                                                <div className={"row input"}>
                                                    <div className={"columns small-12 medium-12"}>
                                                        <div className={"row title"}>
                                                            <div className={"columns"}>
                                                                Versions
                                                            </div>
                                                        </div>
                                                        <div className={"row input"}>
                                                            <div className={"columns small-2"}>
                                                                <div className={"row"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <div className={"row title"}>
                                                                            <div className={"columns"}>
                                                                                WAV <span style={{color:"green"}}>(Uploaded)</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row input"}>
                                                                            <div className={"columns"}>
                                                                                <input type={"file"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"columns small-2"}>
                                                                <div className={"row"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <div className={"row title"}>
                                                                            <div className={"columns"}>
                                                                                MP3 <span style={{color:"green"}}>(Uploaded)</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row input"}>
                                                                            <div className={"columns"}>
                                                                                <input type={"file"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"row input"}>
                                                    <div className={"columns small-12 medium-12"}>
                                                        <div className={"row title"}>
                                                            <div className={"columns"}>
                                                                Loops
                                                            </div>
                                                        </div>
                                                        <div className={"row input"}>
                                                            <div className={"columns small-2"}>
                                                                <div className={"row"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <div className={"row title"}>
                                                                            <div className={"columns"}>
                                                                                WAV <span style={{color:"green"}}>(Uploaded)</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row input"}>
                                                                            <div className={"columns"}>
                                                                                <input type={"file"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"columns small-2"}>
                                                                <div className={"row"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <div className={"row title"}>
                                                                            <div className={"columns"}>
                                                                                MP3 <span style={{color:"green"}}>(Uploaded)</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row input"}>
                                                                            <div className={"columns"}>
                                                                                <input type={"file"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"row input"}>
                                                    <div className={"columns small-12 medium-12"}>
                                                        <div className={"row title"}>
                                                            <div className={"columns"}>
                                                                Stems
                                                            </div>
                                                        </div>
                                                        <div className={"row input"}>
                                                            <div className={"columns small-2"}>
                                                                <div className={"row"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <div className={"row title"}>
                                                                            <div className={"columns"}>
                                                                                WAV <span style={{color:"green"}}>(Uploaded)</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row input"}>
                                                                            <div className={"columns"}>
                                                                                <input type={"file"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"columns small-2"}>
                                                                <div className={"row"}>
                                                                    <div className={"columns small-12 medium-12"}>
                                                                        <div className={"row title"}>
                                                                            <div className={"columns"}>
                                                                                MP3 <span style={{color:"green"}}>(Uploaded)</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row input"}>
                                                                            <div className={"columns"}>
                                                                                <input type={"file"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </ScrollLock>
                </Tabs>
            )
        } else {
            return(
                <div style={{height:"100%"}}>
                    <ContentLoader
                        height={40}
                    >
                        <rect x="5" y="5" rx="4" ry="4" width="80" height="8" />
                        <rect x="90" y="5" rx="4" ry="4" width="80" height="8" />
                        <rect x="180" y="5" rx="4" ry="4" width="80" height="8" />
                        <rect x="270" y="5" rx="4" ry="4" width="80" height="8" />
                        <rect x="330" y="5" rx="4" ry="4" width="70" height="8" />
                    </ContentLoader>
                    <ContentLoader
                        height={140}
                    >
                        {this.renderShimmers()}
                    </ContentLoader>
                </div>
            )
        }
    }
    renderDonateSection(){
        if(!this.state.isUpdate){
            return(
                <div className={"field row collapse"}>
                    <div className={"columns small-12 medium-12"}>
                        <div className={"row title"}>
                            <div className={"columns"}>
                                Bağış
                            </div>
                        </div>
                        <div className={"row input"}>
                            <div className={"columns small-12 medium-3"}>
                                <div className={"row"}>
                                    <div className={"columns small-12 medium-12"}>
                                        <div className={"row title"}>
                                            <div className={"columns"}>
                                                Adet
                                            </div>
                                        </div>
                                        <div className={"row input"}>
                                            <div className={"columns small-12 medium-12"}>
                                                <NumericInput className={"textInput"} min={1} value={this.state.donatedQty} onChange={(value) => {
                                                    this.setState({donatedQty:(this.state.qty >= value?value:this.state.qty)});
                                                }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
    getStartHours(){
        var avaliableHours = new Array();
        if (Moment().format("MM/DD/YYYY") === this.state.date.value)   // is today
        {
            hours.forEach((hour,i)=>{
                var hourEpoch = Functions.getEpochTimeByDate(this.state.date.value, hour);
                var nowEpoch = Functions.getShortEpochTime(Moment().format("X"));
                if (hourEpoch > nowEpoch) {
                    avaliableHours.push({id:(i+1),label:hour,value:hour});
                }
            });
        } else {
            hours.forEach((hour,i)=>{
                avaliableHours.push({id:(i+1),label:hour,value:hour});
            });
        }
        return avaliableHours;
    }
    renderImage(image){
        if(image > 0){
           return(
               <img src={"https://www.dahataze.com/assets/img/gallery/"+image+".jpg"}/>
           )
        }
    }
    renderShimmers(){
        var shimmers = [{},{},{},{},{},{},{},{},{},{}];
        return shimmers.map((data,i)=>{
            return(
                <rect x="5" y={(i * 16)} rx="4" ry="4" width="395" height="10" />
            );
        })
    }
    renderGallery(){
        var start = (this.state.isSuprise?316:1);
        var totalImage = (this.state.isSuprise?6:314);
        var images = [];
        for(var i = start;i<=(start + totalImage);i++){
            images.push(i);
        }
        return images.map((item)=>{
            return(
                <div className={"columns small-12 medium-3 large-3"}>
                    <div style={{border:"solid 3px #FFF",borderColor:(this.state.image == item?"red":"#FFF")}} onClick={()=>{
                        this.setState({image:item,tabIndex:0});
                    }}>
                        <img src={"https://www.dahataze.com/assets/img/gallery/"+item+".jpg"}/>
                    </div>
                </div>
            );
        })
    }
    render(){
        return (
            <StickyContainer>
                <div id={"projectDetail"}>
                    <div className={"header row"}>
                        <div className={"columns shrink"}>
                            <div className={"title"} style={{marginBottom:10}}>
                                {(this.state.isUpdate?"Update Music":"New Music")}
                            </div>
                        </div>
                        <div className={"columns"}>

                        </div>
                        <div className={"columns shrink"}>
                            <FontAwesomeIcon className={"close"} icon={faTimesCircle} size="x2" onClick={()=>{
                                this.setState({contentAvaliable:true},()=>{
                                    Actions.emit("closeSidePanel");
                                })
                            }}/>
                        </div>
                    </div>
                    {this.renderContent() }
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"columns"}>
                                <Checkbox name="aggreement" color="primary-o" disabled={(this.state.savingForm?true:false)} animation="smooth" value={1}  checked={(this.state.aggreementAccepted?true:false)} onChange={(e) => {
                                    this.setState({aggreementAccepted:!this.state.aggreementAccepted})
                                }}>I'm ready to send publishing</Checkbox>
                            </div>
                            <div className={"columns shrink"}>
                                {this.renderActionButtons()}
                            </div>
                        </div>
                    </div>
                </div>
            </StickyContainer>
        );
    }
    renderActionButtons() {
        if (this.state.isUpdate) {
            return(
                <Button buttonTitle={"button.updateProduct"} loadingTitle={"button.updating"}
                        loading={this.state.savingForm}
                        disabled={(!this.state.aggreementAccepted || this.state.disabled ? true : false)}
                        onClick={() => {
                            this.createOrUpdateProduct();
                        }}/>
            )
        } else {
            return(
                <Button buttonTitle={"button.createProduct"} loadingTitle={"button.creating"}
                        loading={this.state.savingForm}
                        disabled={(!this.state.aggreementAccepted || this.state.disabled ? true : false)}
                        onClick={() => {
                            this.createOrUpdateProduct();
                        }}/>
            )
        }
    }
    async uploadFile(pid,files,target,type){
        var formData = new FormData();
        formData.append("file", files[0]);
        console.log(formData);

        fetch('https://api.audiolinn.com/de/api/products/fileUpdate?id='+pid+'&type='+type+'&target='+target, {
            method: 'POST',
            body: formData,
            headers: {
                'content-length': files[0].size,
                'Admin':true,
                'UserToken': Actions.getUserToken(),
            },
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                console.log("done");
            })
            .catch((err) => {
                console.error(err);
                console.log("err");
            });
    }
    async createOrUpdateProduct(){
        if(this.state.aggreementAccepted){
            try {
                if(true){
                    var data = {
                        method:"POST",
                        id:(this.state.isUpdate?this.state.id:0),
                        pid:(!this.state.isUpdate && this.state.id > 0?this.state.id:0),
                        isUpdate:this.state.isUpdate,
                        name:this.state.name,
                        description:this.state.description,
                        bpm:this.state.bpm,
                        date:this.state.date.value,
                        startTime:this.state.startTime.value,
                        endTime:this.state.endTime.value,
                        cid:this.state.cid,
                        moods:this.state.moodList,
                        tags:this.state.tagList,
                        instruments:this.state.instrumentList,
                    };
                    this.setState({loading:true,savingForm:true,disabled:true});
                    var url = Functions.generateURI("/products/create",data);
                    var response = await fetch(url.address, url.header);
                    response = await response.json();

                    if(response.status){
                        this.setState({formAvaliable:false,savingForm:false,disabled:false},()=>{
                            Actions.emit("closeSidePanel");
                        });
                    } else {

                    }
                }
            } catch(error) {

            }
        }
    }
}

export default withHighcharts(newProduct, Highcharts);
