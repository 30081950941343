import React, {useCallback} from 'react'
import TetherComponent from 'react-tether';

export default class tipBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            attachment:(props.attachment != ""?props.attachment:"top center"),
            element:props.element,
            content:props.content,
            toolTipOpen:false,
            toolTipOpenAnimation:false
        };
        var timer = false;
    }
    componentDidMount(){

    }
    render(){
        var Element = this.state.element;
        var Content = this.state.content;

        return (
            <TetherComponent
                attachment={this.state.attachment}
                constraints={[
                    {
                        to: 'scrollParent',
                        attachment: 'together',
                    },
                ]}
                /* renderTarget: This is what the item will be tethered to, make sure to attach the ref */
                renderTarget={ref => <span ref={ref} onMouseEnter={()=>{
                    clearTimeout(this.timer);
                    this.setState({ toolTipOpen: true },()=>{
                        this.setState({toolTipOpenAnimation:true});
                    });
                }} onMouseLeave={()=>{
                    clearTimeout(this.timer);
                    this.timer = setTimeout(()=>{
                        this.setState({ toolTipOpenAnimation: false },()=>{
                            this.setState({toolTipOpen:false});
                        });
                    },100);
                }} ><Element /></span>}
                /* renderElement: If present, this item will be tethered to the the component returned by renderTarget */
                renderElement={ref =>
                    this.state.toolTipOpen && (
                        <div onMouseEnter={()=>{
                            clearTimeout(this.timer);
                            this.setState({ toolTipOpen: true },()=>{
                                this.setState({toolTipOpenAnimation:true});
                            });
                        }} onMouseLeave={()=>{
                            clearTimeout(this.timer);
                            this.timer = setTimeout(()=>{
                                this.setState({ toolTipOpenAnimation: false },()=>{
                                    this.setState({toolTipOpen:false});
                                });
                            },100);
                        }} ref={ref} className={"transition"} style={{opacity:(this.state.toolTipOpenAnimation?"1":"0"),visibility:(this.state.toolTipOpenAnimation?"visible":"hidden")}}><Content /></div>
                    )
                }
            />
        );
    }
}

