import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import '../assets/css/panel.scss';
import html2canvas from 'html2canvas';
import Autosuggest from 'react-autosuggest';

import ScrollLock, { TouchScrollable } from 'react-scrolllock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobeEurope,faSignOutAlt, faClock, faTachometer, faChevronLeft, faSearch,faUserTie,faTasks, faAnalytics, faCog,faReceipt,faPennant,faUsers,faUserHeadset } from '@fortawesome/pro-regular-svg-icons'
import { StickyContainer, Sticky } from 'react-sticky';
//import img from 'react-image-webp';

import TipBox from '../components/tipBox';

// SECTIONS
import DashBoard from "./dashboard";
import Orders, {OrdersMenu} from "./orders";
import Musics, {MusicsMenu} from "./musics";
import Analytics from "./analytics";
import Activity from "./activity";
import Customers, {CustomersMenu} from "./customers";
import Users from "./users";
import Settings from "./settings";

import Topics from "./topics";
import Posts from "./posts";
import Map from "./map";
import MapGoogle from "./map/google";
import Pdf from "./pdf";

// PANELS
import NewProduct from '../components/sidePanel/newProduct';

// POPUP
import PopUpOffer from '../components/popUp/offer';
import PopupAssignToUser from '../components/popUp/assignToUser';
import PopupCreateCustomer from '../components/popUp/createCustomer';

import Actions from '../helpers/actions';
import {useIntl, FormattedMessage} from "react-intl";
import Offer from "../components/popUp/offer";


const Breadcrumbs = (data) => {
    var titles = data.titles;
    const intl = useIntl();
    return(
        <Route path="*" render={props => {
            var parts = new Array();
            let partsTMP = new Array();
            if(props.location.pathname == "/"){
                partsTMP = new Array("","dashboard","");
            } else {
                partsTMP = props.location.pathname.split("/");
            }


            partsTMP.forEach((part,i)=>{
                var url = ['', ...partsTMP.slice(0, i + 1)];
                var title = (url[url.length - 1] != ""?intl.formatMessage({ id: 'titles.'+url[url.length - 1], defaultMessage:titles[i -1]}):"");

                parts.push({url:url,title:title});
            });
            parts = parts.slice(1, parts.length-1);
            const back = parts[parts.length-2];

            return <h1 className={"columns shrink"}>
                {(parts.length > 1?<Link className="back" to={back.url.join("/").replace(/^\/|\/$/g, '')+"/"}><FontAwesomeIcon icon={faChevronLeft} size="x1"/></Link>:"")}
                {parts.map(crumb)}
            </h1>}} />
    )
}

const crumb = (part, partIndex, parts) => {
    var url = "";
    part.url.forEach((step,i)=>{
        if(i > 0){
            url += step+"/";
        }
    });
    return (<Link className={"link"+(parts.length > partIndex + 1?"":" last")} key={url} to={url}>{part.title}{parts.length > partIndex + 1?" / ":""}</Link>);
}

// AUTO SUGGESTION

    const languages = [
        {
            name: 'Johannattan Clover (Customer)',
            year: 1972
        },
        {
            name: 'My name is donat (Music)',
            year: 2012
        },
        {
            name: 'Dance Monkey (Music)',
            year: 2012
        },
    ];
    // Teach Autosuggest how to calculate suggestions for any given input value.
    const getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : languages.filter(lang =>
            lang.name.toLowerCase().slice(0, inputLength) === inputValue
        );
    };

export default class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sidePanel:false,
            popUp:false,
            lockScroll:false,
            prevPath:"",
            titles:["","","","",""],
            searchValue: '',
            suggestions: [],
            data:{},
            popUpType:"offer",
            sidePanelType:"offer"
        };
        Actions.register("closeSidePanel",this.closeSidePanel.bind(this));
    }
    closeSidePanel(){
        this.setState({sidePanel:false,lockScroll:false,data:{}});
    }
    onChange = (event, { newValue }) => {
        this.setState({
            searchValue: newValue
        });
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };
    setTitle(title,target){
        var titles = this.state.titles;
        titles[(target - 1)] = title;
        this.setState({titles:titles});
    }
    openPopUp(target,id,vars){
        vars = (typeof vars == "undefined"?{}:vars);
        this.setState({popUp:false,lockScroll:true},()=>{
            setTimeout(()=>{
                this.setState({popUp:true,popUpType:target,data:vars})
            },500);
        });
    }
    openSidePanel(target,id,vars){
        vars = (typeof vars == "undefined"?{}:vars);
        this.setState({sidePanel:false,lockScroll:true},()=>{
            setTimeout(()=>{
                this.setState({sidePanel:true,sidePanelType:target,data:vars})
            },500);
        });
    }
    renderPopUpContainer(){
        switch(this.state.popUpType){

        }
    }
    renderSidePanel(){
        if(this.state.sidePanel){
            switch(this.state.sidePanelType){
                case "newProduct":
                    return(<NewProduct data={this.state.data}/>);
                    break;
            }
        }
    }
    render(){
        return (
            <div id={"panel"}>
                <div className={"header"}>
                    <div className={"row"}>
                        <div className={"columns shrink"}>
                            <img src={"/assets/images/logo.png"} className={"logo"} />
                        </div>
                        <div className="columns">
                            <div id={"panelName"}>
                                <div>Creator Panel</div>
                                <small>Version 0.9.92</small>
                            </div>
                        </div>
                        <div className={"columns shrink"}>
                            <div id={"profile"}>
                                <div className={"row collapse"}>
                                    <div className={"columns shrink"} style={{marginRight:10}}>
                                        <div className={"row collapse"}>
                                            <div className={"columns"}>
                                                <div className="fullName">{Actions.getUser().data.firstName+" "+    Actions.getUser().data.lastName}</div>
                                                <div className="title">YELES Records</div>
                                            </div>
                                            <div className={"columns shrink"}>
                                                <img className={"profileImg"} src={"http://dahataze.com/assets/img/stores/1.png"}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"columns shrink"}>
                                        <TipBox isOpen={this.state.toolTipOpen} attachment={"bottom center"} element={()=>{
                                            return(
                                                <a className={"logOut"} onClick={()=>{
                                                    Actions.logout();
                                                    return false;
                                                }}>
                                                    <FontAwesomeIcon  icon={faSignOutAlt} size="2x"/>
                                                </a>
                                            )
                                        }} content={()=>{
                                            return(
                                                <p className={"tipBoxWrapper small"}>
                                                    <FormattedMessage
                                                        id="panel.logoutTitle"
                                                    />
                                                </p>
                                            )
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <StickyContainer>
                    <div className={"row"}>
                        <div id="sideMenu" className="columns shrink">
                            <Sticky>

                                {({
                                      style,

                                      // the following are also available but unused in this example
                                      isSticky,
                                      wasSticky,
                                      distanceFromTop,
                                      distanceFromBottom,
                                      calculatedHeight
                                  }) => (
                                    <nav style={style}>
                                        <ul>
                                            <li className={(this.props.location.pathname.split("/")[1] == ""?"active":"")}>
                                                <TipBox isOpen={this.state.toolTipOpen} attachment={"middle left"} element={()=>{
                                                    return(
                                                        <Link to={"/"} onClick={()=>{this.setState({sidePanel:false,popUp:false,lockScroll:false})}}>
                                                            <FontAwesomeIcon  icon={faTachometer} size="lg"/>
                                                        </Link>
                                                    )
                                                }} content={()=>{
                                                    return(
                                                        <p className={"tipBoxWrapper small"}>
                                                            <FormattedMessage
                                                                id="panel.dashBoardTitle"
                                                            />
                                                        </p>
                                                    )
                                                }}/>
                                            </li>
                                            <li className={(this.props.location.pathname.split("/")[1] == "musics"?"active":"")}>
                                                <TipBox isOpen={this.state.toolTipOpen} attachment={"middle left"} element={()=>{
                                                    return(
                                                        <Link to={"/musics/"} onClick={()=>{this.setState({sidePanel:false,popUp:false,lockScroll:false})}}>
                                                            <FontAwesomeIcon  icon={faTasks} size="lg"/>
                                                        </Link>
                                                    )
                                                }} content={()=>{
                                                    return(
                                                        <p className={"tipBoxWrapper small"}>
                                                            <FormattedMessage
                                                                id="panel.musicsTitle"
                                                            />
                                                        </p>
                                                    )
                                                }}/>
                                            </li>
                                            <li className={(this.props.location.pathname.split("/")[1] == "orders"?"active":"")}>
                                                <TipBox isOpen={this.state.toolTipOpen} attachment={"middle left"} element={()=>{
                                                    return(
                                                        <Link to={"/orders/"} onClick={()=>{this.setState({sidePanel:false,popUp:false,lockScroll:false})}}>
                                                            <FontAwesomeIcon  icon={faTasks} size="lg"/>
                                                        </Link>
                                                    )
                                                }} content={()=>{
                                                    return(
                                                        <p className={"tipBoxWrapper small"}>
                                                            <FormattedMessage
                                                                id="panel.ordersTitle"
                                                            />
                                                        </p>
                                                    )
                                                }}/>
                                            </li>
                                            <li className={(this.props.location.pathname.split("/")[1] == "analytics"?"active":"")}>
                                                <TipBox isOpen={this.state.toolTipOpen} attachment={"middle left"} element={()=>{
                                                    return(
                                                        <Link to={"/analytics/summary/"} onClick={()=>{this.setState({sidePanel:false,popUp:false,lockScroll:false})}}>
                                                            <FontAwesomeIcon  icon={faAnalytics} size="lg"/>
                                                        </Link>
                                                    )
                                                }} content={()=>{
                                                    return(
                                                        <p className={"tipBoxWrapper small"}>
                                                            <FormattedMessage
                                                                id="panel.analyticsTitle"
                                                            />
                                                        </p>
                                                    )
                                                }}/>
                                            </li>
                                            <li className={(this.props.location.pathname.split("/")[1] == "activity"?"active":"")}>
                                                <TipBox isOpen={this.state.toolTipOpen} attachment={"middle left"} element={()=>{
                                                    return(
                                                        <Link to={"/activity/"} onClick={()=>{this.setState({sidePanel:false,popUp:false,lockScroll:false})}}>
                                                            <FontAwesomeIcon  icon={faGlobeEurope} size="lg"/>
                                                        </Link>
                                                    )
                                                }} content={()=>{
                                                    return(
                                                        <p className={"tipBoxWrapper small"}>
                                                            <FormattedMessage
                                                                id="panel.activityTitle"
                                                            />
                                                        </p>
                                                    )
                                                }}/>
                                            </li>
                                            <li className={(this.props.location.pathname.split("/")[1] == "users"?"active":"")}>
                                                <TipBox isOpen={this.state.toolTipOpen} attachment={"middle left"} element={()=>{
                                                    return(
                                                        <Link to={"/users/"} onClick={()=>{this.setState({sidePanel:false,popUp:false,lockScroll:false})}}>
                                                            <FontAwesomeIcon  icon={faUsers} size="lg"/>
                                                        </Link>
                                                    )
                                                }} content={()=>{
                                                    return(
                                                        <p className={"tipBoxWrapper small"}>
                                                            <FormattedMessage
                                                                id="panel.usersTitle"
                                                            />
                                                        </p>
                                                    )
                                                }}/>
                                            </li>
                                            <li className={(this.props.location.pathname.split("/")[1] == "settings"?"active":"")}>
                                                <TipBox isOpen={this.state.toolTipOpen} attachment={"middle left"} element={()=>{
                                                    return(
                                                        <Link to={"/settings/general/"} onClick={()=>{this.setState({sidePanel:false,popUp:false,lockScroll:false})}}>
                                                            <FontAwesomeIcon  icon={faCog} size="lg"/>
                                                        </Link>
                                                    )
                                                }} content={()=>{
                                                    return(
                                                        <p className={"tipBoxWrapper small"}>
                                                            <FormattedMessage
                                                                id="panel.settingsTitle"
                                                            />
                                                        </p>
                                                    )
                                                }}/>
                                            </li>
                                        </ul>
                                    </nav>
                                )}
                            </Sticky>
                        </div>
                        <div id={"panelContent"} className="columns">
                            <div>
                                <div id={"sidePanel"} className={"row collapse align-right transition"+(this.state.sidePanel?" open":"")} style={{maxHeight:(window.innerHeight - 68)+"px"}}>
                                    <div className={"outTrigger"} onClick={()=>{this.setState({sidePanel:false,lockScroll:false})}}></div>
                                    <Sticky topOffset={13}>
                                        {({
                                              style,

                                              // the following are also available but unused in this example
                                              isSticky,
                                              wasSticky,
                                              distanceFromTop,
                                              distanceFromBottom,
                                              calculatedHeight
                                          }) => (
                                            <div style={style} className={"columns shrink noPadding transition"}>
                                                <div>
                                                    {this.renderSidePanel()}
                                                </div>
                                            </div>
                                        )}
                                    </Sticky>
                                </div>
                                <div id={"popUp"} className={"row collapse align-right transition"+(this.state.popUp?" open":"")}>
                                    <div className={"outTrigger"} onClick={()=>{this.setState({popUp:false,lockScroll:false})}}></div>
                                    {this.renderPopUpContainer()}
                                </div>
                                <Sticky topOffset={13}>
                                    {({
                                          style,

                                          // the following are also available but unused in this example
                                          isSticky,
                                          wasSticky,
                                          distanceFromTop,
                                          distanceFromBottom,
                                          calculatedHeight
                                      }) => (
                                        <div style={style} className={"header"+(isSticky?" sticked":"")}>
                                            <div className={"row collapse transition"}>
                                                <Breadcrumbs setTitle={this.setTitle.bind(this)} titles={this.state.titles}/>
                                                <div className={"columns"}>
                                                    <div className={"row align-right"}>
                                                        <div className={"columns shrink"}>
                                                            <Switch>
                                                                <Route path="/orders" render={(props) => <OrdersMenu {...props} setTitle={this.setTitle.bind(this)} openSidePanel={this.openSidePanel.bind(this)} openPopUp={this.openPopUp.bind(this)} url={this.props.location.pathname.split("/")} /> }/>
                                                                <Route path="/musics" render={(props) => <MusicsMenu {...props} setTitle={this.setTitle.bind(this)} openSidePanel={this.openSidePanel.bind(this)} openPopUp={this.openPopUp.bind(this)} url={this.props.location.pathname.split("/")} /> }/>
                                                                <Route path="/customers" render={(props) => <CustomersMenu {...props} setTitle={this.setTitle.bind(this)} openSidePanel={this.openSidePanel.bind(this)} openPopUp={this.openPopUp.bind(this)} url={this.props.location.pathname.split("/")} /> }/>
                                                            </Switch>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Sticky>
                                <div className={"content"}>
                                    <div className={"columns"}>
                                        <Switch>
                                            <Route path="/pdf" render={(props) => <Pdf {...props} setTitle={this.setTitle.bind(this)} openSidePanel={this.openSidePanel.bind(this)} openPopUp={this.openPopUp.bind(this)} url={this.props.location.pathname.split("/")} /> }/>
                                            <Route path="/map/google" render={(props) => <MapGoogle {...props} setTitle={this.setTitle.bind(this)} openSidePanel={this.openSidePanel.bind(this)} openPopUp={this.openPopUp.bind(this)} url={this.props.location.pathname.split("/")} /> }/>
                                            <Route path="/map" render={(props) => <Map {...props} setTitle={this.setTitle.bind(this)} openSidePanel={this.openSidePanel.bind(this)} openPopUp={this.openPopUp.bind(this)} url={this.props.location.pathname.split("/")} /> }/>
                                            <Route path="/topics" render={(props) => <Topics {...props} setTitle={this.setTitle.bind(this)} openSidePanel={this.openSidePanel.bind(this)} openPopUp={this.openPopUp.bind(this)} url={this.props.location.pathname.split("/")} /> }/>
                                            <Route path="/posts" render={(props) => <Posts {...props} setTitle={this.setTitle.bind(this)} openSidePanel={this.openSidePanel.bind(this)} openPopUp={this.openPopUp.bind(this)} url={this.props.location.pathname.split("/")} /> }/>
                                            <Route path="/orders" render={(props) => <Orders {...props} setTitle={this.setTitle.bind(this)} openOrderForm={(vars)=>{
                                                this.setState({sidePanel:true,lockScroll:true,sidePanelType:"order",data:vars});
                                            }} openSidePanel={this.openSidePanel.bind(this)} openPopUp={this.openPopUp.bind(this)} url={this.props.location.pathname.split("/")} /> }/>
                                            <Route path="/musics" render={(props) => <Musics {...props} setTitle={this.setTitle.bind(this)} openOrderForm={(vars)=>{
                                                this.setState({sidePanel:true,lockScroll:true,sidePanelType:"order",data:vars});
                                            }} openSidePanel={this.openSidePanel.bind(this)} openPopUp={this.openPopUp.bind(this)} url={this.props.location.pathname.split("/")} /> }/>
                                            <Route path="/analytics" render={(props) => <Analytics {...props} setTitle={this.setTitle.bind(this)} openSidePanel={this.openSidePanel.bind(this)} openPopUp={this.openPopUp.bind(this)} url={this.props.location.pathname.split("/")} /> }/>
                                            <Route path="/activity" render={(props) => <Activity {...props} setTitle={this.setTitle.bind(this)} openSidePanel={this.openSidePanel.bind(this)} openPopUp={this.openPopUp.bind(this)} url={this.props.location.pathname.split("/")} /> }/>
                                            <Route path="/customers" render={(props) => <Customers {...props} setTitle={this.setTitle.bind(this)} openSidePanel={this.openSidePanel.bind(this)} openPopUp={this.openPopUp.bind(this)} url={this.props.location.pathname.split("/")} /> }/>
                                            <Route path="/users" render={(props) => <Users {...props} setTitle={this.setTitle.bind(this)} openSidePanel={this.openSidePanel.bind(this)} openPopUp={this.openPopUp.bind(this)} url={this.props.location.pathname.split("/")} /> }/>
                                            <Route path="/settings" render={(props) => <Settings {...props} setTitle={this.setTitle.bind(this)} openSidePanel={this.openSidePanel.bind(this)} openPopUp={this.openPopUp.bind(this)} url={this.props.location.pathname.split("/")} /> }/>
                                            <Route path="/" render={(props) => <DashBoard {...props} setTitle={this.setTitle.bind(this)} openSidePanel={this.openSidePanel.bind(this)} openPopUp={this.openPopUp.bind(this)} url={this.props.location.pathname.split("/")} /> }/>
                                        </Switch>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </StickyContainer>
                <div className={"footer"}>
                    <div className="info row">
                        <div className="columns">
                            <div className={"content"}>
                                <div className="legal">
                                    <div>This panel created for producers manage their library and orders.</div>
                                    <div>
                                        If you thing any problem on this page pelase <a onClick={()=>{
                                        html2canvas(document.querySelector("#root")).then(canvas => {
                                            // Get base64URL
                                            var base64URL = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
                                            console.log(base64URL);

                                        });
                                    }}>click</a> here for report problem.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="columns shrink">
                            <div className={"content"}>
                                <div>
                                    <span className="copyRight">
                                        ©{new Date().getFullYear()}&nbsp;-&nbsp;
                                        <FormattedMessage
                                            id="app.copyrightText"
                                        />
                                    </span>
                                </div>
                                <div>
                                    <TipBox isOpen={this.state.toolTipOpen} attachment={"bottom center"} element={()=>{
                                        return(
                                            <a href="https://www.omg.works" target="_blank" className={"dev"}>
                                                <img src={"/assets/images/omg_logo.svg"} alt="Design &amp; Development"/>
                                            </a>
                                        )
                                    }} content={()=>{
                                        return(
                                            <p className={"tipBoxWrapper small"}>
                                                <FormattedMessage
                                                    id="app.developedByText"
                                                />
                                            </p>
                                        )
                                    }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollLock isActive={this.state.lockScroll} />
            </div>
        );
    }
}
