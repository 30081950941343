import React, {useCallback} from 'react'
import {FormattedMessage} from "react-intl";
import IntlTelInput from "react-intl-tel-input";
import {ClassicSpinner} from "react-spinners-kit";


export default class PdfLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonTitle:this.props.buttonTitle,
            loadingTitle:this.props.loadingTitle,
            loading:this.props.loading,
            disabled:this.props.state
        };
    }
    componentDidMount(){

    }
    componentWillReceiveProps(nextProps){
        this.setState({
            buttonTitle:nextProps.buttonTitle,
            loadingTitle:nextProps.loadingTitle,
            loading:nextProps.loading,
            disabled:nextProps.disabled
        });
    }
    render(){
        return (
            <a className={"transition button component"+(this.state.loading || this.state.disabled?" disabled":"")+(this.state.loading?" loading":"")} onClick={()=>{
                this.props.onClick();
            }}>
                <FormattedMessage
                    id={this.state.loading?this.state.loadingTitle:this.state.buttonTitle}
                />{this.state.loading?"...":""}
                {this.state.loading?
                    <span className={"inputSpinner"}>
                                                        <ClassicSpinner
                                                            size={16}
                                                            color="#555"
                                                            loading={this.state.loading}
                                                        />
                                                    </span>:null}
            </a>
        );
    }
}

