import React, {useCallback} from 'react'

import {
    PDFDownloadLink
} from '@react-pdf/renderer';

export default class PdfLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isReady:false
        };
    }
    componentDidMount(){
        setTimeout(()=>{
            this.setState({isReady:true});
        },100);
    }
    render(){
        return (
            <div>
                {this.renderLink()}
            </div>
        );
    }
    renderLink(){
        if(this.state.isReady){
            return(
                <PDFDownloadLink
                    document={this.props.document}
                    style={{ }}
                    fileName="AudienceReport.pdf"
                    className="pdf-link"
                >
                    <span style={{color:"red",fontFamily:'Open Sans'}}>İndir</span>
                </PDFDownloadLink>
            );
        } else {
            return(
                <div style={{color:"red",fontFamily:'Open Sans'}}>Yükleniyor</div>
            )
        }
    }
}

