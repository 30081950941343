import React, {useCallback} from 'react'
import '../../assets/css/pop.scss';

import { Checkbox,Radio } from 'pretty-checkbox-react';
import InputMask from 'react-input-mask';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/pro-regular-svg-icons";
import Actions from "../../helpers/actions";
import Functions from "../../helpers/functions";
import Select from "react-select";
import 'react-tabs/style/react-tabs.css';
import Stepper from "react-stepper-horizontal";


const selectStyle = {
    option: (provided, state) => ({
        ...provided,
    }),
    control: (provided, state) => ({
        ...provided,
        position:'relative',
        zIndex:1005
    }),
}


export default class offer extends React.Component {
    constructor(props) {
        super(props);
        this.defaultVars = {
            step:0,
            offerName:"",
            customers:[],
            categories:[],
            subCategories:[],
            brand:{ value: '0', label: 'Select brand' },
            customer:{ value: '0', label: 'Select customer' },
            model:{ value: '0', label: 'Select product model' },
            type:{ value: '0', label: 'Select product type' },
            done:false
        };

        this.state = this.defaultVars;

        this.title = ["Select Customer","Select Brand","Select Product Model","Select Product Type","Enter Project Name","Offer Summary"];
    }
    componentDidMount(){
        this.getCustomers();
    }
    async getCustomers(){
        try {
            var url = Functions.generateURI("/customers/getAll",{});

            var response = await fetch(url.address, url.header);
            response = await response.json();

            if(response.status){

                var customers = [];
                customers.push({ value: 0, label: "Select Customer" });

                response.data.forEach((data)=>{
                    customers.push({ value: data.id, label: data.name+" (#"+data.id+")" });
                });
                this.setState({customers:customers});
            } else {

            }
        } catch(error) {

        }
    }
    async getTemplateList(){
        this.setState({loading:true});
        try {
            var url = Functions.generateURI(
                "/templates/categories",
                {

                }
            );

            var response = await fetch(url.address, url.header);
            response = await response.json();

            if(response.status){

                var categories = [];
                categories.push({ value: 0, label: "Model Seçiniz" });

                response.data.forEach((data)=>{
                   categories.push({ value: data.id, label: data.name, templateCode:data.TemmplateCode, childs:data.childs });
                });
                this.setState({categories:categories});
            } else {

            }
        } catch(error) {

        }
    }
    render(){

        return (
            <div id={"popForm"}>
                <div className={"row"}>
                    <div className={"columns small-12"}>
                        <div className={"title"}>{this.title[this.state.step]}</div>
                        <div className={"stepper"}>
                            <Stepper titleTop={4} circleTop={0} size={26} circleFontSize={18} defaultBorderStyle={{lineHeight:0}} steps={ [{title:"Customer"},{title:"Brand"}, {title:"Model"},{title:"Type"}, {title:"Name"}, {title:"Summary"}] } titleFontSize={12} defaultTitleColor={"#666"} activeTitleColor={"#4085c7"} completeTitleColor={"#4085c7"} activeBorderColor={"#666"} defaultBarColor={"#CCC"} activeStep={ this.state.step} />
                        </div>
                        <div className={"container"}>
                            {this.renderStep(this.state.step)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    renderStep(step){
        switch(step){
            case 0:
                return(
                    <div className="inputWrapper">
                        <Select
                            styles={selectStyle}
                            value={this.state.custmer}
                            onChange={(val)=>{
                                this.setState({customer:val});
                            }}
                            options={this.state.customers}
                        />
                        <div className="buttons">
                            <a className={"button "+(this.state.customer.value == 0?"disabled":"")} onClick={()=>{
                                this.getTemplateList();
                                this.setState({step:1});
                            }}>Start</a>
                        </div>
                    </div>
                );
                break;
            case 1:
                return(
                    <div className="inputWrapper">
                        <Select
                            styles={selectStyle}
                            value={this.state.brand}
                            onChange={(val)=>{
                                this.setState({brand:val});
                            }}
                            options={[
                                { value: 0, label: "Select brand" },
                                { value: 1, label: "Doğan Yıldız" },
                                { value: 2, label: "Petro Gas" },
                            ]}
                        />
                        <div className="buttons">
                            <a className={"button"} onClick={()=>{
                                this.setState({step:0});
                            }}>Back</a>
                            <a className={"button "+(this.state.brand.value == 0?"disabled":"")} onClick={()=>{
                                if(this.state.brand.value != 0){
                                    this.setState({step:2});
                                }
                            }}>Continue</a>
                        </div>
                    </div>
                );
                break;
            case 2:
                return(
                    <div className="inputWrapper">
                        <Select
                            styles={selectStyle}
                            value={this.state.model}
                            onChange={(val)=>{
                                this.setState({model:val});
                            }}
                            options={this.state.categories}
                        />
                        <div className="buttons">
                            <a className={"button"} onClick={()=>{
                                this.setState({step:1});
                            }}>Back</a>
                            <a className={"button "+(this.state.model.value == 0?"disabled":"")} onClick={()=>{
                                if(this.state.model.value != 0){
                                    var subCategories = [];
                                    subCategories.push({ value: 0, label: "Select product type" });
                                    this.state.categories.forEach((data)=>{
                                        if(parseInt(data.value) == parseInt(this.state.model.value)){
                                            if(data.childs.length != null){
                                                data.childs.forEach((data2)=>{
                                                    subCategories.push({ value: data2.id, label: data2.name, templateCode:data2.TemplateCode});
                                                });
                                                this.setState({subCategories:subCategories,step:3})
                                            } else {
                                                this.setState({step:3})
                                            }
                                        }
                                    });
                                }
                            }}>Continue</a>
                        </div>
                    </div>
                );
                break;
            case 3:
                return(
                    <div className="inputWrapper">
                        <Select
                            styles={selectStyle}
                            value={this.state.type}
                            onChange={(val)=>{
                                this.setState({type:val});
                            }}
                            options={this.state.subCategories}
                        />
                        <div className="buttons">
                            <a className={"button"} onClick={()=>{
                                this.setState({step:2});
                            }}>Back</a>
                            <a className={"button "+(this.state.type.value == 0?"disabled":"")} onClick={()=>{
                                if(this.state.type.value != 0){
                                    this.setState({step:4});
                                }
                            }}>Continue</a>
                        </div>
                    </div>
                );
                break;
            case 4:
                return(
                    <div className="inputWrapper">
                        <input className={"input"} type={"text"} value={this.state.offerName} style={{textAlign:'center'}}
                               onChange={(e) => {
                                   this.setState({offerName:e.target.value});
                               }} placeholder={"Project name"} />
                        <div className="buttons">
                            <a className={"button"} onClick={()=>{
                                this.setState({step:5});
                            }}>Continue</a>
                        </div>
                    </div>
                );
                break;
            case 5:
                return(
                    <div className="inputWrapper">
                        <div className={"resume row"}>
                            <div className={"columns small-12 medium-12 large-12"}>
                                <div className={"stepName"}>Offer Name:</div>
                                <div className={"stepValue"}>{this.state.offerName}</div>
                            </div>
                            <div className={"columns small-12 medium-6 large-6"}>
                                <div className={"stepName"}>Brand:</div>
                                <div className={"stepValue"}>{this.state.brand.label}</div>
                            </div>
                            <div className={"columns small-12 medium-6 large-6"}>
                                <div className={"stepName"}>Customer:</div>
                                <div className={"stepValue"}>{this.state.customer.label}</div>
                            </div>
                            <div className={"columns small-12 medium-6 large-6"}>
                                <div className={"stepName"}>Model:</div>
                                <div className={"stepValue"}>{this.state.model.label}</div>
                            </div>
                            <div className={"columns small-12 medium-6 large-6"}>
                                <div className={"stepName"}>Type:</div>
                                <div className={"stepValue"}>{this.state.type.label}</div>
                            </div>
                        </div>
                        <div className="buttons">
                            <a className={"button"} onClick={()=>{
                                this.setState({step:3});
                            }}>Back</a>
                            <a className={"button"} onClick={()=>{
                                this.setState({done:true},()=>{
                                    this.props.closePopUp();
                                    this.props.openOfferForm(this.state);
                                    this.setState(this.defaultVars);
                                });
                            }}>Open Offer Form</a>
                        </div>
                    </div>
                );
                break;
        }
    }
}

