import React, {useCallback} from 'react'
import '../../assets/css/pop.scss';

import { Checkbox,Radio } from 'pretty-checkbox-react';
import InputMask from 'react-input-mask';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/pro-regular-svg-icons";
import Actions from "../../helpers/actions";
import Functions from "../../helpers/functions";
import Select from "react-select";
import 'react-tabs/style/react-tabs.css';
import Stepper from "react-stepper-horizontal";


const selectStyle = {
    option: (provided, state) => ({
        ...provided,
    }),
    control: (provided, state) => ({
        ...provided,
        position:'relative',
        zIndex:1005
    }),
}


export default class createCustomer extends React.Component {
    constructor(props) {
        super(props);
        this.defaultVars = {
            step:0,
            done:false,
            companyName:"",
            companyAddress:"",
            contactFullName:"",
            contactEmail:"",
            contactPhone:""
        };

        this.state = this.defaultVars;

        this.title = ["Customer Info","Contact Info"];
    }
    componentDidMount(){

    }
    async createCustomerNow(){
        this.setState({loading:true});
        try {

            var url = Functions.generateURI(
                "/customers/create",
                {
                    companyName:this.state.companyName,
                    companyAddress:this.state.companyAddress,
                    contactFullName:this.state.contactFullName,
                    contactEmail:this.state.contactEmail,
                    contactPhone:this.state.contactPhone,
                    method:"POST"
                }
            );

            var response = await fetch(url.address, url.header);
            response = await response.json();

            if(response.status){
                this.props.closePopUp();
            } else {

            }
        } catch(error) {

        }
    }
    render(){

        return (
            <div id={"popForm"}>
                <div className={"row"}>
                    <div className={"columns small-12"}>
                        <div className={"title"}>{this.title[this.state.step]}</div>
                        <div className={"stepper"}>
                            <Stepper titleTop={4} circleTop={0} size={26} circleFontSize={18} defaultBorderStyle={{lineHeight:0}} steps={ [{title:"Company"},{title:"Customer"}] } titleFontSize={12} defaultTitleColor={"#666"} activeTitleColor={"#4085c7"} completeTitleColor={"#4085c7"} activeBorderColor={"#666"} defaultBarColor={"#CCC"} activeStep={ this.state.step} />
                        </div>
                        <div className={"container"}>
                            {this.renderStep(this.state.step)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    renderStep(step){
        switch(step){
            case 0:
                return(
                    <div className="inputWrapper">
                        <input className={"input"} type={"text"} value={this.state.companyName} style={{textAlign:'center'}}
                               onChange={(e) => {
                                   this.setState({companyName:e.target.value});
                               }} placeholder={"Company name"} />
                        <input className={"input"} type={"text"} value={this.state.companyAddress} style={{textAlign:'center'}}
                               onChange={(e) => {
                                   this.setState({companyAddress:e.target.value});
                               }} placeholder={"Company address"} />
                        <div className="buttons">
                            <a className={"button"} onClick={()=>{
                                this.setState({step:1});
                            }}>Continue</a>
                        </div>
                    </div>
                );
                break;
            case 1:
                return(
                    <div className="inputWrapper">
                        <input className={"input"} type={"text"} value={this.state.contactFullName} style={{textAlign:'center'}}
                               onChange={(e) => {
                                   this.setState({contactFullName:e.target.value});
                               }} placeholder={"Contact name"} />
                        <input className={"input"} type={"text"} value={this.state.contactEmail} style={{textAlign:'center'}}
                               onChange={(e) => {
                                   this.setState({contactEmail:e.target.value});
                               }} placeholder={"Contact e-mail"} />
                        <input className={"input"} type={"text"} value={this.state.contactPhone} style={{textAlign:'center'}}
                               onChange={(e) => {
                                   this.setState({contactPhone:e.target.value});
                               }} placeholder={"Contact phone"} />
                        <div className="buttons">
                            <a className={"button"} onClick={()=>{
                                this.createCustomerNow();
                            }}>Create</a>
                        </div>
                    </div>
                );
                break;
        }
    }
}

