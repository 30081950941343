import React from 'react';
import {Link } from "react-router-dom";
import Actions from '../../helpers/actions';
import Functions from '../../helpers/functions';
import Locale from '../../assets/languages/locale.json';
import TipBox from '../../components/tipBox';
import DateRangePicker from '../../components/dateRangePicker';

import {FormattedMessage,FormattedNumber,FormattedDate,FormattedTime,FormattedRelativeTime} from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faChevronRight,
    faChevronLeft, faClock, faPhone, faEnvelope, faBoxCheck, faBallotCheck, faInfoCircle
} from '@fortawesome/pro-regular-svg-icons'
import { StickyContainer, Sticky } from 'react-sticky';

import Select from 'react-select';
import {Line} from "rc-progress";
import Highcharts from 'highcharts';
import {
    HighchartsChart, Chart, withHighcharts, XAxis, YAxis, Tooltip, Title, Subtitle, Legend,ColumnSeries,PieSeries, LineSeries, SplineSeries, AreaSeries,AreaSplineSeries, Annotation
} from 'react-jsx-highcharts';
import ReactPaginate from "react-paginate";
import Stepper from "react-stepper-horizontal";
import Switch from "react-ios-switch/lib";

require("highcharts/modules/annotations")(Highcharts);


class Activitiy extends React.Component {
    constructor(props) {
        super(props);
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var data1 = [];
        var data2 = [];

        var date1 = new Date(y, m, 0);
        var date2 = new Date(y, m - 1, 0);

        for(var i =0;i<30;i++){
            date1.setDate(date1.getDate() + 1);
            data1.push([Functions.getFormattedDate(date1,"DD MMM YYYY"), (Math.floor(Math.random() * 20) + 1 )]);

            date2.setDate(date2.getDate() + 1);
            data2.push([Functions.getFormattedDate(date2,"DD MMM YYYY"), (Math.floor(Math.random() * 20) + 1 )]);
        }

        this.state = {
            dateTimeRange:[
                {startDate:new Date(y, m, 1),endDate:new Date(y, m + 1, 0)}
            ],
            filter:{
                state:{ value: '0', label: 'All' },
                session:{ value: '0', label: 'All times' },
                types:[true,true,true,true]
            },
            data1:data1,
            data2:data2
        };

        var chartOptions = {
            dateTime:Locale.tr.dateTime,
            legend:{

            },
            yAxis:{
                endOnTick: false,
                maxPadding: 0
            },
            xAxis: {
                crosshair: {
                    width: 20,
                    color: 'rgba(204, 214, 235, 0.25)'
                },
                categories: this.state.data1,
                labels:{
                    formatter: function() {
                        var date = this.value.toString().split(',')[0];
                        date = Functions.getFormattedDate(date,"DD / MM");
                        return date;
                    }
                }
            }
        }
    }
    componentDidMount(){
        this.dataLoader = false;
        setTimeout(()=>{
            var loadingData = Actions.emit("showLoadingToast");
            setTimeout(()=>{
                Actions.emit("hideToast");
                Actions.emit("showToast",{message:"All data loaded"});
            },2000)
        },100);
    }
    componentWillMount(){
        clearTimeout(this.dataLoader);
        Actions.emit("hideToast");
    }
    changeFilter(target,value){
        var filter = this.state.filter;
        filter[target] = value;
        this.setState({filter:filter},()=>{

        });
        return false;
    }
    render(){
        return (
            <div id={"activity"}>
                <StickyContainer>
                    <div className={"row"}>
                        <div className={"columns small-12 medium-4"}>
                            <div className={"stickyWrapper"}>
                                <div>
                                    <Sticky topOffset={-62}>

                                        {({
                                              isSticky,
                                              style,
                                          }) => (
                                            <div className={"row collapse"}>
                                                <div className={"columns small-12"} style={{...style, marginTop: isSticky ? '62px' : '0px'}} >
                                                    <div className={"block info"}>
                                                        <div className={"group"}>
                                                            <div className={"container"}>
                                                                <b>Summary</b>
                                                                <p>
                                                                    On selected date range <a onClick={()=>{this.changeFilter("status",{ value: 'strawberry', label: 'Listen' });}}><b>0</b></a> music listened. <a><b>0</b></a> music added favorites, for <a><b>0</b></a> requested special edit and 0 music sold.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"block filter"}>
                                                        <div className={"group"}>
                                                            <div className={"container"}>
                                                                <b>Activities</b>
                                                                <ul>
                                                                    {this.renderActivityTypes()}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Sticky>
                                </div>
                            </div>
                        </div>
                        <div className={"columns small medium-8"}>
                            <Sticky topOffset={-38}>

                                {({
                                      isSticky,
                                      style,
                                  }) => (
                                    <div id={"filter"} className={"columns small-12 block noMargin"} style={{...style, marginTop: isSticky ? '38px' : '0px'}} >
                                        <div>
                                            <div className="row">
                                                <div className="columns">
                                                    <div className={"row"}>
                                                        <div className={"columns small-7"}>
                                                            <div className="inputHeader">Customers</div>
                                                            <Select
                                                                value={this.state.filter.state}
                                                                onChange={(val)=>{
                                                                    this.changeFilter("state",val);
                                                                }}
                                                                options={[
                                                                    { value: '0', label: 'Tüm' },
                                                                    { value: 'chocolate', label: 'Ahmet Yusuf Besim' },
                                                                    { value: 'strawberry', label: 'John Doe' },
                                                                    { value: 'vanilla', label: 'Serdar  Bakır' },
                                                                    { value: 'vanilla', label: 'Cenk  Yeles' },
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="columns shrink">
                                                    <div className="inputHeader">Tarih aralığı</div>
                                                    <DateRangePicker onChange={(ranges)=>{
                                                        this.setState({
                                                            dateTimeRange:ranges
                                                        });

                                                    }} ranges={this.state.dateTimeRange}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Sticky>
                            <div className={"columns small-12 block marginTop"}>
                                <div className="list">
                                    <div className={"header row"}>
                                        <div className={"columns name shrink"}>
                                            <b>Kullanıcı</b>
                                        </div>
                                        <div className={"columns shrink"}>
                                            <b>Haraket</b>
                                        </div>
                                    </div>
                                    <div className={"container"}>
                                        <ul>
                                            <li className={"line"}></li>
                                            {this.renderUserList()}
                                        </ul>
                                        <div className={"pagination"}>
                                            <ReactPaginate
                                                previousLabel={<FontAwesomeIcon icon={faChevronLeft} size="lg"/>}
                                                nextLabel={<FontAwesomeIcon icon={faChevronRight} size="lg"/>}
                                                breakLabel={'...'}
                                                breakClassName={'break-me'}
                                                pageCount={22}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={()=>{
                                                    Functions.scrollTo(0);
                                                }}
                                                containerClassName={'pagination'}
                                                subContainerClassName={'pages pagination'}
                                                activeClassName={'active'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </StickyContainer>
            </div>
        );
    }
    renderActivityTypes(){
        var types = [
            {icon:faPhone,title:"Listen music"},
            {icon:faEnvelope,title:"Added favorites"},
            {icon:faBoxCheck,title:"Special requests"},
            {icon:faBallotCheck,title:"Created order"}
        ];
        return types.map((type,i)=>{
            return(
                <li className={"row"+(this.state.filter.types[i]?" active":" passive")}>
                    <div className={"columns shrink noPaddingRight"}>
                        <span className={"type"+i}><FontAwesomeIcon icon={type.icon} size="lg"/></span>
                    </div>
                    <div className={"columns"}>
                        <div className={"row"}>
                            <div className={"columns"}>
                                <b className={"title"}>{type.title}</b>
                                <div className={"status"}>
                                    <b>Status:</b> {this.state.filter.types[i]?"Showing":"Hidden"}
                                </div>
                            </div>
                            <div className={"column shrink"}>
                                <Switch
                                    checked={this.state.filter.types[i]}
                                    className={"switch small"}
                                    handleColor="white"
                                    offColor="white"
                                    onChange={(checked) => {
                                        var filter = this.state.filter;
                                        filter.types[i] = !filter.types[i];
                                        this.setState({filter:filter});
                                    }}
                                    onColor="rgb(102, 170, 234)"
                                />
                            </div>
                        </div>
                    </div>
                </li>
            )
        });
    }
    renderIcon(type){
        var icons = [<FontAwesomeIcon icon={faPhone} size="lg"/>,<FontAwesomeIcon icon={faEnvelope} size="lg"/>,<FontAwesomeIcon icon={faBoxCheck} size="lg"/>,<FontAwesomeIcon icon={faBallotCheck} size="lg"/>]
        return <span className={"type"+type}>{icons[type]}</span>;
    }
    renderUserList(){
        var data = [
                {type:0,"description":"{musicName} named music listened"},
                {type:1,"description":"{musicName} named music added 'Beach musuic' list"},
                {type:1,"description":"{musicName} named music added 'Rockn roll' list"},
                {type:2,"description":"For {musicName} requested special editing"},
                {type:3,"description":"Created order - Total price: 34$"}
            ];
        return data.map((data,key)=>{
            if(this.state.filter.types[data.type]){
                return(
                    <li className={"row align-middle user"} key={"u_"+key}>
                        <div className={"columns name shrink"}>
                            <div className={"title"}>
                                <div className={"row collapse"}>
                                    <div className={"columns shrink"}>
                                        <img className="image transition" src={Functions.fakeProfileImage()}/>
                                    </div>
                                    <div className={"columns"}>
                                        <a onClick={()=>{
                                            //this.props.openSidePanel("users",3)
                                        }}><b>Ahmet Yusuf Besim</b></a>
                                        <small>yusuf@artperpixel.com</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"columns shrink noPaddingLeft"}>
                            <div className={"action"}>
                                {this.renderIcon(data.type)}
                            </div>
                        </div>
                        <div className={"columns description"}>
                            <b>
                                <FormattedMessage
                                    id="message"
                                    defaultMessage={data.description}
                                    values={{
                                        musicName: <a href='#'>Music Monkey</a>,
                                    }}
                                />
                            </b>
                            <small>
                                <TipBox attachment={"bottom center"} element={()=>{
                                    return(
                                        <span>
                                        <FontAwesomeIcon  icon={faClock} size="lg"/> <FormattedRelativeTime value={((new Date().getTime() - (60*3)) - new Date().getTime())} numeric="auto" updateIntervalInSeconds={10} />
                                    </span>
                                    )
                                }} content={()=>{
                                    return(
                                        <p className={"tipBoxWrapper small"}>
                                            <FormattedDate
                                                value={new Date(1459832991883)}
                                                year="numeric"
                                                month="long"
                                                day="2-digit"
                                            />
                                        </p>
                                    )
                                }}/>
                            </small>
                        </div>
                    </li>
                );
            }
        });
    }
}
export default withHighcharts(Activitiy, Highcharts);
