import React from 'react';
import {Link } from "react-router-dom";
import Actions from '../../helpers/actions';
import Functions from '../../helpers/functions';
import Locale from '../../assets/languages/locale.json';
import TipBox from '../../components/tipBox';

import {FormattedMessage,FormattedNumber,FormattedDate,FormattedTime,FormattedRelativeTime} from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faChevronRight,
    faInfoCircle,
    faChartLineDown,
    faChartLine,
    faPrint,
    faChevronLeft
} from '@fortawesome/pro-regular-svg-icons'
import { StickyContainer, Sticky } from 'react-sticky';
import Select from 'react-select';
import Switch from "react-ios-switch/lib";
import CharacterCounter from 'react-character-counter'


class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter:{
                state:{ value: '0', label: 'Tümü' },
                session:{ value: '0', label: 'Tüm Zamanlar' },
            },
            systemEmail:"info@audiolinn.com"
        };
    }
    componentDidMount(){
        this.dataLoader = false;
        setTimeout(()=>{
            var loadingData = Actions.emit("showLoadingToast");
            setTimeout(()=>{
                Actions.emit("hideToast");
                Actions.emit("showToast",{message:"All data loaded"});
            },2000)
        },100);
    }
    componentWillMount(){
        clearTimeout(this.dataLoader);
        Actions.emit("hideToast");
    }
    render(){
        return (
            <div id={"settings"}>
                <StickyContainer>
                    <div className={"row"}>
                        <div className={"columns sperated"}>
                            <div className={"columns small-12"}>
                                <div className={"field row"}>
                                    <div className={"columns"}>
                                        <div className={"title"}>Status</div>
                                        <div className={"description"}>For stop access to creator panel you can use switch</div>
                                    </div>
                                    <div className={"columns shrink"}>
                                        <div className={"switchWrapper row"}>
                                            <div className={"text"}>Active</div>
                                            <div className={"input"}>
                                                <Switch
                                                    checked={true}
                                                    className={"switch small"}
                                                    handleColor="white"
                                                    offColor="white"
                                                    onChange={(checked) => {

                                                    }}
                                                    onColor="rgb(102, 170, 234)"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"columns small-12"}>
                                <div className={"fieldGroup row"}>
                                    <div className={"columns small-12 medium-6"}>
                                        <div className={"field row"}>
                                            <div className={"columns small-12"}>
                                                <div className={"title"}>System E-mail</div>
                                                <div className={"description"}>Standart information mails sends from this mail</div>
                                            </div>
                                            <div className={"columns small-12"}>
                                                <div className={"inputWrapper"}>
                                                    <CharacterCounter
                                                        value={this.state.systemEmail}
                                                        maxLength={120}
                                                    >
                                                        <input type='text'
                                                               maxLength={120}
                                                               onChange={(e) => {this.setState({systemEmail:e.target.value})}}
                                                               value={this.state.systemEmail}
                                                        />
                                                    </CharacterCounter>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"columns small-12 medium-6"}>
                                        <div className={"field row"}>
                                            <div className={"columns"}>
                                                <div className={"title"}></div>
                                                <div className={"description"}></div>
                                            </div>
                                            <div className={"columns shrink"}>
                                                <div className={"switchWrapper row"}>
                                                    <div className={"text"}>Active</div>
                                                    <div className={"input"}>
                                                        <Switch
                                                            checked={true}
                                                            className={"switch small"}
                                                            handleColor="white"
                                                            offColor="white"
                                                            onChange={(checked) => {

                                                            }}
                                                            onColor="rgb(102, 170, 234)"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"columns shrink"}>
                            <div className={"stickyWrapper"}>
                                <div>
                                    <Sticky topOffset={-62}>

                                        {({
                                              isSticky,
                                              style,
                                          }) => (
                                            <div className={"row collapse"}>
                                                <div className={"columns small-12"} style={{...style, marginTop: isSticky ? '62px' : '0px'}} >
                                                    <div className={"block menu"}>
                                                        <div className={"group"}>
                                                            <div className={"container"}>
                                                                <ul>
                                                                    <li className={"active"}>
                                                                        <Link to={"/settings/general/"}><FontAwesomeIcon icon={faChevronLeft} size="lg"/> General</Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Sticky>
                                </div>
                            </div>
                        </div>
                    </div>
                </StickyContainer>
            </div>
        );
    }
}
export default Settings;
