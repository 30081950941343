import React from 'react';
import {Link } from "react-router-dom";
import Actions from '../../helpers/actions';
import Functions from '../../helpers/functions';
import Locale from '../../assets/languages/locale.json';

import TipBox from '../../components/tipBox';

import {FormattedMessage,FormattedNumber,FormattedDate,FormattedTime,FormattedRelativeTime} from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faLifeRing,
    faChevronRight,
    faChevronLeft,
    faEnvelope,
    faInfoCircle,
    faSignOutAlt,
    faFileExport,
    faFileDownload,
    faFilePdf,
    faTrash, faPencil
} from '@fortawesome/pro-regular-svg-icons'
import { StickyContainer, Sticky } from 'react-sticky';
import Stepper from 'react-stepper-horizontal';
import CsvDownloader from 'react-csv-downloader';
import ReactPaginate from 'react-paginate';
import ProductItem from '../../components/productItem';

import Select from 'react-select';
import {Line} from "rc-progress";
import Highcharts from 'highcharts';
import {
    HighchartsChart,
    Chart,
    withHighcharts,
    XAxis,
    YAxis,
    Tooltip,
    Title,
    Subtitle,
    Legend,
    LineSeries,
    SplineSeries,
    PieSeries, AreaSeries, HighchartsSparkline
} from 'react-jsx-highcharts';
import Avatar from "react-avatar";
import ContentLoader from "react-content-loader";
import Switch from "react-ios-switch/lib";

var searchTimer = false;

const selectStyle = {
    option: (provided, state) => ({
        ...provided,
    }),
    control: (provided, state) => ({
        ...provided,
        position:'relative',
        zIndex:1005
    }),
}


class Musics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter:{
                state:{ value: '0', label: 'All' },
                status:{ value: '0', label: 'All' },
                order:{ value: 'time_az', label: 'Music name A-Z' }
            },
            searchKey:"",
            data:[],
            csvData:[]
        };
        Highcharts.setOptions(Locale.tr.dateTime);
    }
    componentDidMount(){
        Actions.register("closeSidePanel",this.getProducts.bind(this));
        this.getProducts();
    }
    async getProducts(){
        this.setState({data:[],loading:true});
        try {
            var filter = {};
            Object.keys(this.state.filter).forEach((key)=>{
                if(this.state.filter[key].value != null){
                    filter["filter_"+key] = this.state.filter[key].value;
                }
            });
            filter["searchKey"] = this.state.searchKey;
            var url = Functions.generateURI("/products/get",filter);

            var response = await fetch(url.address, url.header);
            response = await response.json();

            if(response.status){
                var csvData = [];
                response.data.products.forEach((productGroup)=>{
                    productGroup.forEach((product)=>{
                        csvData.push({
                            id: product.id,
                            name: product.name,
                            model: product.model,
                            stock_sold:product.stock_sold,
                            created:product.created,
                            updated:product.updated,
                        });
                    });
                });
                this.setState({data:response.data.products,csvData:csvData},()=>{

                });
            } else {

            }
        } catch(error) {

        }
    }
    changeFilter(target,value){
        var filter = this.state.filter;
        filter[target] = value;
        this.setState({filter:filter},()=>{
            this.getProducts();
        });
        return false;
    }
    render(){
        return (
            <div id={"projects"}>
                <StickyContainer>
                    <div className={"row"}>
                        <div className={"columns small medium-9"}>
                            <Sticky topOffset={-38}>

                                {({
                                      isSticky,
                                      style,
                                  }) => (
                                    <div id={"filter"} className={"columns small-12 block noMargin"} style={{...style, marginTop: isSticky ? '38px' : '0px'}} >
                                        <div>
                                            <div className="row">
                                                <div className="columns small-2">
                                                    <div className="inputHeader">Status</div>
                                                    <Select
                                                        styles={selectStyle}
                                                        value={this.state.filter.status}
                                                        onChange={(val)=>{
                                                            this.changeFilter("status",val);
                                                        }}
                                                        options={[
                                                            { value: '0', label: 'Pending' },
                                                            { value: 'chocolate', label: 'In Review' },
                                                            { value: 'strawberry', label: 'Declined' },
                                                            { value: 'vanilla', label: 'Approved' }
                                                        ]}
                                                    />
                                                </div>
                                                <div className="columns">
                                                    <div className={"row align-right"}>
                                                        <div className="columns small-6">
                                                            <div className="inputHeader">Search</div>
                                                            <input type={"text"} placeholder={"Enter keyword.."}
                                                                   onChange={(e) => {
                                                                       var value = e.target.value;
                                                                       this.setState({searchKey:value});
                                                                       clearTimeout(searchTimer);
                                                                       searchTimer = setTimeout(()=>{
                                                                           this.getProducts();
                                                                       },500);
                                                                   }}
                                                                   value={this.state.searchKey}/>
                                                        </div>
                                                        <div className="columns small-4">
                                                            <div className="inputHeader">Sort by</div>
                                                            <Select
                                                                styles={selectStyle}
                                                                value={this.state.filter.order}
                                                                onChange={(val)=>{
                                                                    this.changeFilter("order",val);
                                                                }}
                                                                options={[
                                                                    { value: 'atz', label: 'Music name A-Z' },
                                                                    { value: 'zta', label: 'Music name Z-A' },
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Sticky>
                            <div className={"columns small-12 block marginTop wrapContainer"}>
                                <div className="list">
                                    <div className={"header row"}>
                                        <div className={"columns shrink"}>
                                            <div className={"title"}><b>Customer</b></div>
                                        </div>
                                        <div className={"columns shrink"}>
                                            <div className={"projectName"}><b>Statics</b></div>
                                        </div>
                                        <div className={"columns shrink"}>
                                            <div className={"projectSteps"}><b>Status</b></div>
                                        </div>
                                        <div className={"columns"}>
                                            &nbsp;
                                        </div>
                                    </div>
                                    <div className={"container"}>
                                        <ul>
                                            {this.renderItemList()}
                                        </ul>
                                        <div className={"pagination"} style={{display:"none"}}>
                                            <ReactPaginate
                                                previousLabel={<FontAwesomeIcon icon={faChevronLeft} size="lg"/>}
                                                nextLabel={<FontAwesomeIcon icon={faChevronRight} size="lg"/>}
                                                breakLabel={'...'}
                                                breakClassName={'break-me'}
                                                pageCount={22}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={()=>{
                                                    Functions.scrollTo(0);
                                                }}
                                                containerClassName={'pagination'}
                                                subContainerClassName={'pages pagination'}
                                                activeClassName={'active'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"columns small-12 medium-3"}>
                            <div className={"stickyWrapper"}>
                                <div>
                                    <Sticky topOffset={-62}>

                                        {({
                                              isSticky,
                                              style,
                                          }) => (
                                            <div className={"row collapse"}>
                                                <div className={"columns small-12"} style={{...style, marginTop: isSticky ? '62px' : '0px'}} >
                                                    <div className={"block info"}>
                                                        <div className={"group"}>
                                                            <div className={"container"}>
                                                                <b>Status</b>
                                                                <p>
                                                                    Total <a onClick={()=>{this.changeFilter("status",0)}}><b>{this.state.data.length}</b></a> active.
                                                                    <a onClick={()=>{this.changeFilter("status",{ value: 'strawberry', label: 'Görüşme' });}}><b>0</b></a> passive.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className={"group"}>
                                                            <div className={"row"}>
                                                                <div className={"columns small-6"}>
                                                                    <div className={"container"}>
                                                                        <b className={"small"}>
                                                                            Music
                                                                            <span className={"info"}>
                                                                                <TipBox attachment={"bottom center"} element={()=>{
                                                                                    return(
                                                                                        <FontAwesomeIcon icon={faInfoCircle} size="1x"/>
                                                                                    )
                                                                                }} content={()=>{
                                                                                    return(
                                                                                        <p className={"tipBoxWrapper small"}>
                                                                                            <FormattedMessage
                                                                                                id="panel.xxx"
                                                                                            />
                                                                                        </p>
                                                                                    )
                                                                                }}/>
                                                                            </span>
                                                                        </b>
                                                                        <div className="progress">
                                                                            <div className={"wrapper"}>
                                                                                <div className={"count"}>{this.state.data.length}<small>%0</small></div>
                                                                                <Line percent="0" strokeWidth="2" strokeColor="#7bb5ec" trailWidth={2} trailColor="#e6e6e6"/>
                                                                            </div>
                                                                            <div className="text">
                                                                                <a href="#a" className="action link">Show <FontAwesomeIcon icon={faChevronRight} size="xs"/></a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={"columns small-6"}>
                                                                    <div className={"container"}>
                                                                        <b className={"small"}>
                                                                            Sold
                                                                            <span className={"info"}>
                                                                                <TipBox attachment={"bottom center"} element={()=>{
                                                                                    return(
                                                                                        <FontAwesomeIcon icon={faInfoCircle} size="1x"/>
                                                                                    )
                                                                                }} content={()=>{
                                                                                    return(
                                                                                        <p className={"tipBoxWrapper small"}>
                                                                                            <FormattedMessage
                                                                                                id="panel.xxx"
                                                                                            />
                                                                                        </p>
                                                                                    )
                                                                                }}/>
                                                                            </span>
                                                                        </b>
                                                                        <div className="progress">
                                                                            <div className={"wrapper"}>
                                                                                <div className={"count"}>0<small>%0</small></div>
                                                                                <Line percent="0" strokeWidth="2" strokeColor="#7bb5ec" trailWidth={2} trailColor="#e6e6e6"/>
                                                                            </div>
                                                                            <div className="text">
                                                                                <a href="#a" className="action link">Show <FontAwesomeIcon icon={faChevronRight} size="xs"/></a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={"group"}>
                                                            <div className={"row"}>
                                                                <div className={"columns small-6"}>
                                                                    <div className={"container"}>
                                                                        <b className={"small"}>
                                                                            Approved
                                                                            <span className={"info"}>
                                                                                <TipBox attachment={"bottom center"} element={()=>{
                                                                                    return(
                                                                                        <FontAwesomeIcon icon={faInfoCircle} size="1x"/>
                                                                                    )
                                                                                }} content={()=>{
                                                                                    return(
                                                                                        <p className={"tipBoxWrapper small"}>
                                                                                            <FormattedMessage
                                                                                                id="panel.xxx"
                                                                                            />
                                                                                        </p>
                                                                                    )
                                                                                }}/>
                                                                            </span>
                                                                        </b>
                                                                        <div className="progress">
                                                                            <div className={"wrapper"}>
                                                                                <div className={"count"}>0<small>%0</small></div>
                                                                                <Line percent="0" strokeWidth="2" strokeColor="#7bb5ec" trailWidth={2} trailColor="#e6e6e6"/>
                                                                            </div>
                                                                            <div className="text">
                                                                                <a href="#a" className="action link">Show <FontAwesomeIcon icon={faChevronRight} size="xs"/></a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={"columns small-6"}>
                                                                    <div className={"container"}>
                                                                        <b className={"small"}>
                                                                            Declined
                                                                            <span className={"info"}>
                                                                                <TipBox attachment={"bottom center"} element={()=>{
                                                                                    return(
                                                                                        <FontAwesomeIcon icon={faInfoCircle} size="1x"/>
                                                                                    )
                                                                                }} content={()=>{
                                                                                    return(
                                                                                        <p className={"tipBoxWrapper small"}>
                                                                                            <FormattedMessage
                                                                                                id="panel.xxx"
                                                                                            />
                                                                                        </p>
                                                                                    )
                                                                                }}/>
                                                                            </span>
                                                                        </b>
                                                                        <div className="progress">
                                                                            <div className={"wrapper"}>
                                                                                <div className={"count"}>0<small>%0</small></div>
                                                                                <Line percent="0" strokeWidth="2" strokeColor="#7bb5ec" trailWidth={2} trailColor="#e6e6e6"/>
                                                                            </div>
                                                                            <div className="text">
                                                                                <a href="#a" className="action link">Show <FontAwesomeIcon icon={faChevronRight} size="xs"/></a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={"group"}>
                                                            <div className={"container"}>
                                                                <div style={{marginTop:16}}>
                                                                    <HighchartsChart>
                                                                        <Chart backgroundColor={"transparent"} margin={0} height={200}/>
                                                                        <Legend />

                                                                        <XAxis categories={[]} />
                                                                        <Tooltip  valueSuffix=" adet" />
                                                                        <YAxis>
                                                                            <PieSeries name="İşlem" data={[{
                                                                                name: 'Approved',
                                                                                y: 0
                                                                            }, {
                                                                                name: 'Active',
                                                                                y: 0
                                                                            }, {
                                                                                name: 'Passive',
                                                                                y: 0
                                                                            }, {
                                                                                name: 'Declined',
                                                                                y: 0
                                                                            }]} />
                                                                        </YAxis>
                                                                    </HighchartsChart>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Sticky>
                                </div>
                            </div>
                        </div>
                    </div>
                </StickyContainer>
            </div>
        );
    }
    renderItemList(){
        if(this.state.data.length > 0){
            var i = 0;
            return this.state.data.map((data,key)=>{
                i++;
                if(i < 2000 ){
                    return (<ProductItem updateProduct={this.updateProduct.bind(this)} key={"project_"+key} data={data} deleteProduct={this.deleteProduct.bind(this)} />);
                }
            });
        } else {
            var shimmers = [{},{},{},{},{},{},{},{},{},{}];
            return shimmers.map((data,i)=>{
                return(
                    <li className={"row shimmer"} key={"u_"+i}>
                        <div className={"columns small-12"}>
                            <ContentLoader
                                width={1350}
                                height={50}
                            >
                                <rect x="0" y="0" rx="25" ry="25" width="50" height="50" />
                                <rect x="60" y="7" rx="4" ry="4" width="240" height="16" />
                                <rect x="60" y="30" rx="4" ry="4" width="240" height="10" />
                                <rect x="350" y="7" rx="4" ry="4" width="300" height="16" />
                                <rect x="350" y="30" rx="4" ry="4" width="300" height="10" />
                                <rect x="786" y="0" rx="25" ry="25" width="50" height="50" />
                                <rect x="856" y="0" rx="25" ry="25" width="50" height="50" />
                                <rect x="926" y="0" rx="25" ry="25" width="50" height="50" />
                                <rect x="996" y="0" rx="25" ry="25" width="50" height="50" />
                                <rect x="1066" y="0" rx="25" ry="25" width="50" height="50" />
                                <rect x="1170" y="0" rx="25" ry="25" width="50" height="50" />
                            </ContentLoader>
                        </div>
                    </li>
                );
            })
        }
    }
    async updateProduct(data){
        data.isUpdate = true;
        this.props.openSidePanel("newProduct",data.id,data);
    }
    async deleteProduct(id){

        this.setState({loading:true});
        try {
            var url = Functions.generateURI("/products/delete",{method:"GET",id:id});

            var response = await fetch(url.address, url.header);
            response = await response.json();
            this.getProducts();
        } catch(error) {

        }
    }
}
export class MusicsMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    componentDidMount(){

    }
    render(){
        return (
            <div>
                <a onClick={()=>{
                    this.props.openSidePanel("newProduct",1);
                }} className={"button small green"}>Send New Music</a>
            </div>
        );
    }
}

export default withHighcharts(Musics, Highcharts);
