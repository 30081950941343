import React from 'react';
import {Link } from "react-router-dom";
import Actions from '../../helpers/actions';
import Functions from '../../helpers/functions';
import Locale from '../../assets/languages/locale.json';

import TipBox from '../../components/tipBox';

import {FormattedMessage,FormattedNumber,FormattedDate,FormattedTime,FormattedRelativeTime} from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faLifeRing, faChevronRight,faEnvelope,faExclamationTriangle} from '@fortawesome/pro-regular-svg-icons'
import { StickyContainer, Sticky } from 'react-sticky';
import { Line, Circle } from 'rc-progress';
import Avatar from 'react-avatar';

import Select from 'react-select';
import Swiper from 'react-id-swiper';

import Highcharts from 'highcharts';
import {
    HighchartsChart, Chart, withHighcharts, XAxis, YAxis, Tooltip, Title, Subtitle, Legend, LineSeries, SplineSeries
} from 'react-jsx-highcharts';
import {Formik} from "formik";

class DashBoard extends React.Component {
    constructor(props) {
        super(props);
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var data1 = [];
        var data2 = [];

        var date1 = new Date(y, m, 0);
        var date2 = new Date(y, m - 1, 0);

        for(var i =0;i<7;i++){
            date1.setDate(date1.getDate() + 1);
            data1.push([Functions.getFormattedDate(date1,"DD MMM YYYY"), (Math.floor(Math.random() * 20) + 1 )]);

            date2.setDate(date2.getDate() + 1);
            data2.push([Functions.getFormattedDate(date2,"DD MMM YYYY"), (Math.floor(Math.random() * 20) + 1 )]);
        }

        this.state = {
            data1:data1,
            data2:data2
        };

        var chartOptions = {
            alignTicks: false,
            dateTime:Locale.tr.dateTime,
            yAxis:{
                endOnTick: false,
                maxPadding: 0
            },
            xAxis: {
                categories: this.state.data1,
                labels:{
                    formatter: function() {
                        date = Functions.getFormattedDate(this.value,"DD / MM");
                        return date;
                    }
                }
            }
        }

        Highcharts.setOptions(chartOptions);
    }
    render(){
        return (
            <div id={"dashboard"}>
                <div className={"row"}>
                    <div className={"columns small-12"}>
                        <div className={"columns small-12 block noPad"}>
                            <div className={"announcements"}>
                                <Swiper autoplay={true} direction={"vertical"}>
                                    <div>
                                        <FontAwesomeIcon icon={faLifeRing} size="lg"/> <b>Duyuru</b>: Sistem kullanımı yaparken bir sorun ile karşılaşırsanız bize bildirin.<span
                                        className="sperate">|</span><a href="#x" className="feedback" data-type="0">Geri
                                        bildirim için tıklayın</a>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faLifeRing} size="lg"/> <b>Duyuru</b>: Sistem kullanımı yaparken bir sorun ile karşılaşırsanız bize bildirin.<span
                                        className="sperate">|</span><a href="#x" className="feedback" data-type="0">Geri
                                        bildirim için tıklayın</a>
                                    </div>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                    <div className={"columns small-6"}>
                        <div className={"columns small-12 block fixed"}>
                            <div className={"row"}>
                                <div className={"columns small-12 medium-6"}>
                                    <div className="recent">
                                        <b>Latest active users</b>
                                        <ul className="faces row">
                                            <li className="columns shrink">
                                                <div className="visitor">
                                                    <img className="transition" src={Functions.fakeProfileImage()}/>
                                                </div>
                                            </li>
                                            <li className="columns shrink">
                                                <div className="visitor">
                                                    <img className="transition" src={Functions.fakeProfileImage()}/>
                                                </div>
                                            </li>
                                            <li className="columns shrink">
                                                <div className="visitor">
                                                    <img className="transition" src={Functions.fakeProfileImage()}/>
                                                </div>
                                            </li>
                                            <li className="columns shrink">
                                                <div className="visitor">
                                                    <img className="transition" src={Functions.fakeProfileImage()}/>
                                                </div>
                                            </li>
                                            <li className="columns shrink">
                                                <div className="visitor">
                                                    <img className="transition" src={Functions.fakeProfileImage()}/>
                                                </div>
                                            </li>
                                            <li className="columns shrink">
                                                <div className="visitor">
                                                    <img className="transition" src={Functions.fakeProfileImage()}/>
                                                </div>
                                            </li>
                                            <li className="columns shrink">
                                                <div className="visitor">
                                                    <img className="transition" src={Functions.fakeProfileImage()}/>
                                                </div>
                                            </li>
                                            <li className="columns shrink">
                                                <div className="visitor">
                                                    <img className="transition" src={Functions.fakeProfileImage()}/>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="text">
                                            <a href="#a" className="action link">Hepsini gör <FontAwesomeIcon icon={faChevronRight} size="xs"/></a>
                                        </div>
                                    </div>
                                </div>
                                <div className={"columns small-12 medium-6"}>
                                    <div className={"graph"}>
                                        <Swiper pagination={{
                                            el: '.swiper-pagination',
                                            type: 'bullets',
                                            clickable: true
                                        }} autoplay={true}>
                                            <div>
                                                <b>Performans</b>
                                                <div >
                                                    <HighchartsChart plotOptions={{
                                                    }} >
                                                        <Chart height={100} backgroundColor={"transparent"} margin={20} marginBottom={10} marginLeft={32}/>
                                                        <XAxis tickInterval={4}>
                                                            <XAxis.Title>Time</XAxis.Title>
                                                        </XAxis>

                                                        <Tooltip valueSuffix=" music" />
                                                        <YAxis tickInterval={3}>
                                                            <SplineSeries name="Listen" data={this.state.data1} />
                                                            <SplineSeries name="Sold" data={this.state.data2} />
                                                        </YAxis>
                                                    </HighchartsChart>
                                                </div>
                                            </div>
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"columns small-6"}>
                        <div className={"columns small-12 block fixed"}>
                            <div className={"row"}>
                                <div className={"columns small-12 medium-6"}>
                                    <div className="progress">
                                        <b>Approved Musics</b>
                                        <p>Approved musics send by you. <b>88</b> percent music approved already</p>
                                        <div className={"wrapper"}>
                                            <div className={"count"}>59<small>%88</small></div>
                                            <Line percent="88" strokeWidth="4" strokeColor="#7bb5ec" trailWidth={4} trailColor="#e6e6e6"/>
                                        </div>
                                        <div className="text">
                                            <a href="#a" className="action link">List <FontAwesomeIcon icon={faChevronRight} size="xs"/></a>
                                        </div>
                                    </div>
                                </div>
                                <div className={"columns small-12 medium-6"}>
                                    <div className="progress">
                                        <b>Special requests</b>
                                        <p>Requested by customer waiting for reply. <b>38</b> percent request replied</p>
                                        <div className={"wrapper"}>
                                            <div className={"count"}>12<small>%38</small></div>
                                            <Line percent="38" strokeWidth="4" strokeColor="#7bb5ec" trailWidth={4} trailColor="#e6e6e6"/>
                                        </div>
                                        <div className="text">
                                            <a href="#a" className="action link">Listele <FontAwesomeIcon icon={faChevronRight} size="xs"/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"columns small-12 medium-8"}>
                        <div className={"columns small-12 block"}>
                            <div className="list">
                                <div className={"header row"}>
                                    <div className={"columns shrink"}>
                                        <div className={"title"}><b>Customer</b></div>
                                    </div>
                                    <div className={"columns small-7"}>
                                        <b>Status</b>
                                    </div>
                                    <div className={"columns"}>

                                    </div>
                                </div>
                                <div className={"container"}>
                                    <ul>
                                        {this.renderUserList()}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"columns small-12 medium-4"}>
                        <div className={"stickyWrapper"}>
                            <StickyContainer>
                                <div className="activity">
                                    <Sticky bottomOffeset={100}>

                                        {({
                                              style,
                                          }) => (
                                              <div className={"row collapse"}>
                                                  <div style={style} className={"columns small-12"}>
                                                      <div className={"info box"}>
                                                          <Swiper autoplay={true} direction={"vertical"}>
                                                              <div className={"row collapse"}>
                                                                  <div className={"columns shrink"}>
                                                                      <FontAwesomeIcon icon={faExclamationTriangle} size="lg"/>
                                                                  </div>
                                                                  <div className={"columns"}>
                                                                      <p>
                                                                          You have problem on payments. Please review bank informations
                                                                      </p>
                                                                  </div>
                                                                  <div className={"columns shrink more"}>
                                                                      <FontAwesomeIcon icon={faChevronRight} size="lg"/>
                                                                  </div>
                                                              </div>
                                                              <div className={"row collapse"}>
                                                                  <div className={"columns shrink"}>
                                                                      <FontAwesomeIcon icon={faExclamationTriangle} size="lg"/>
                                                                  </div>
                                                                  <div className={"columns"}>
                                                                      <p>
                                                                          Attention! 3 of music need confirmation in 3 day.
                                                                      </p>
                                                                  </div>
                                                                  <div className={"columns shrink more"}>
                                                                      <FontAwesomeIcon icon={faChevronRight} size="lg"/>
                                                                  </div>
                                                              </div>
                                                          </Swiper>
                                                      </div>
                                                      <div className={"block"}>
                                                          <div>
                                                              <b>Last activities</b>
                                                              <Link target={"/activities"} className="action link">Show all <FontAwesomeIcon icon={faChevronRight} size="xs"/></Link>
                                                              <div className={"container"}>
                                                                  <div className={"line"}></div>
                                                                  <ul>
                                                                      {this.renderActivities()}
                                                                  </ul>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                        )}
                                    </Sticky>
                                </div>
                            </StickyContainer>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    renderUserList(){
        var data = [{},{},{},{},{},{}];
        return data.map((data,key)=>{
            return(
                <li className={"row user"} key={"u_"+key}>
                    <div className={"columns name shrink"}>
                        <div className={"title"}>
                            <div className={"row collapse"}>
                                <div className={"columns shrink"}>
                                    <Avatar color={"#5598d6"} name="L P G" src={Functions.fakeProfileImage()} size={35} round={true}/>
                                </div>
                                <div className={"columns"}>
                                    <a onClick={()=>{
                                        this.props.openSidePanel("project",3)
                                    }}><b>Joseph Turner</b></a>
                                    <small>yusuf@artperpixel.com</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"columns small-6 name"}>
                        <b>4 Items sold. 44$ Income (%90 of price)</b>
                        <small><FormattedRelativeTime value={((new Date().getTime() - (60*3)) - new Date().getTime())} numeric="auto" updateIntervalInSeconds={10} /></small>
                    </div>
                    <div className={"columns"}>
                        <div className={"row align-right"}>
                            <div className={"columns shrink"}>
                                <Link to={"google"} className={"button small"}>Reciept</Link>
                            </div>
                        </div>
                    </div>
                </li>
            );
        });
    }
    renderActivities(){
        var data = [{},{}];
        return data.map((data,key)=>{
            return(
                <li className={"row collapse"} key={"a_"+key}>
                    <div className={"columns shrink"}>
                        <img className={"image"} src="https://app.botonom.com/assets/img/visitors/1330.jpg"/>
                    </div>
                    <div className={"columns icon shrink"}>
                        <FontAwesomeIcon icon={faEnvelope} size="1x"/>
                    </div>
                    <div className={"columns"}>
                        <div className={"detail"}>
                            <div className={"fullName"}>Suzana Yeles</div>
                            <div className={"date"}>
                                <FormattedDate
                                    value={new Date()}
                                    year="numeric"
                                    month="long"
                                    day="2-digit"
                                />
                            </div>
                            <div className={"description"}>
                                <b>Reviewed your music</b><br/>
                                Your music named "Blabla Song" rejected because of unsupported format.
                            </div>
                        </div>
                    </div>
                </li>
            );
        });
    }
}
export default withHighcharts(DashBoard, Highcharts);
