import React, {useCallback} from 'react'
import {DateRangePicker} from "react-date-range";
import trLocale from "react-date-range/dist/locale/tr";
import {FormattedMessage,FormattedNumber,FormattedDate,FormattedTime,FormattedRelativeTime} from 'react-intl';
import {faChevronDown} from '@fortawesome/pro-regular-svg-icons'

import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    isSameDay,
    differenceInCalendarDays,
} from 'date-fns';

import Translation from './translation';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

const staticRangeHandler = {
    range: {},
    isSelected(range) {
        const definedRange = this.range();
        return (
            isSameDay(range.startDate, definedRange.startDate) &&
            isSameDay(range.endDate, definedRange.endDate)
        );
    },
};

export function createStaticRanges(ranges) {
    return ranges.map(range => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
    {
        label: <Translation text={"dates.todayTitle"}/>,
        range: () => ({
            startDate: defineds.startOfToday,
            endDate: defineds.endOfToday,
        }),
    },
    {
        label: 'Yesterday',
        range: () => ({
            startDate: defineds.startOfYesterday,
            endDate: defineds.endOfYesterday,
        }),
    },

    {
        label: 'This Week',
        range: () => ({
            startDate: defineds.startOfWeek,
            endDate: defineds.endOfWeek,
        }),
    },
    {
        label: 'Last Week',
        range: () => ({
            startDate: defineds.startOfLastWeek,
            endDate: defineds.endOfLastWeek,
        }),
    },
    {
        label: 'This Month',
        range: () => ({
            startDate: defineds.startOfMonth,
            endDate: defineds.endOfMonth,
        }),
    },
    {
        label: 'Last Month',
        range: () => ({
            startDate: defineds.startOfLastMonth,
            endDate: defineds.endOfLastMonth,
        }),
    },
]);

export const defaultInputRanges = [
    {
        label: 'days up to today',
        range(value) {
            return {
                startDate: addDays(defineds.startOfToday, (Math.max(Number(value), 1) - 1) * -1),
                endDate: defineds.endOfToday,
            };
        },
        getCurrentValue(range) {
            if (!isSameDay(range.endDate, defineds.endOfToday)) return '-';
            if (!range.startDate) return '∞';
            return differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1;
        },
    },
    {
        label: 'days starting today',
        range(value) {
            const today = new Date();
            return {
                startDate: today,
                endDate: addDays(today, Math.max(Number(value), 1) - 1),
            };
        },
        getCurrentValue(range) {
            if (!isSameDay(range.startDate, defineds.startOfToday)) return '-';
            if (!range.endDate) return '∞';
            return differenceInCalendarDays(range.endDate, defineds.startOfToday) + 1;
        },
    },
];


export default class dateRangePickerComp extends React.Component {
    constructor(props) {
        super(props);


        var ranges = [];
        if(props.ranges){
            props.ranges.forEach((range,i)=>{
                var newRange = {
                    startDate: (range.startDate != ""?range.startDate:new Date()),
                    endDate: (range.endDate != ""?range.endDate:new Date()),
                    key: 'selection_'+i,
                }
                ranges.push(newRange);
            })
        }

        var date = new Date(), y = date.getFullYear(), m = date.getMonth();

        this.state = {
            isDropDownOpen:false,
            ranges:ranges,
            minDate:(typeof props.minDate == undefined?new Date(y - 10, m, 0):props.minDate),
            maxDate:new Date(),
        };
    }
    componentDidMount(){

    }
    renderSecondRange(){
        if(this.state.ranges.length == 2){
             return(
                 <div className={"sub"}>
                     <span>Compare: </span>
                    <span className={"startDate"}>
                                    <FormattedDate
                                        value={this.state.ranges[1].startDate}
                                        year="numeric"
                                        month="short"
                                        day="2-digit"
                                    />
                                </span>
                     <span className={"sperator"}>-</span>
                     <span className={"endDate"}>
                                    <FormattedDate
                                        value={this.state.ranges[1].endDate}
                                        year="numeric"
                                        month="short"
                                        day="2-digit"
                                    />
                                </span>
                 </div>
             );
        }
    }
    render(){
        return (
            <div id={"datePicker"} className={(this.state.isDropDownOpen?"active":"")}>
                <div className={"values"+(this.state.ranges.length == 1?"":" multiple")} onClick={()=>{this.setState({isDropDownOpen:!this.state.isDropDownOpen})}}>
                    <div className={"row collapse"}>
                        <div className={"columns"}>
                            <div className={"main"}>
                                <span className={"startDate"}>
                                    <FormattedDate
                                        value={(this.state.ranges.length == 2?this.state.ranges[1].startDate:this.state.ranges[0].startDate)}
                                        year="numeric"
                                        month="short"
                                        day="2-digit"
                                    />
                                </span>
                                <span className={"sperator"}>-</span>
                                    <span className={"endDate"}>
                                    <FormattedDate
                                        value={(this.state.ranges.length == 2?this.state.ranges[1].endDate:this.state.ranges[0].endDate)}
                                        year="numeric"
                                        month="short"
                                        day="2-digit"
                                    />
                                </span>
                            </div>
                            {this.renderSecondRange()}
                        </div>
                        <div className={"columns shrink"}>
                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false"
                                 className="css-tj5bde-Svg">
                                <path
                                    d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className={"dropDown"}>
                    <DateRangePicker
                        minDate={this.state.minDate}
                        maxDate={this.state.maxDate}
                        showSelectionPreview={false}
                        showMonthAndYearPickers={false}
                        shownDate={false}
                        ranges={this.state.ranges}
                        onChange={(ranges) => {
                            var rangesOld = this.state.ranges;
                            if(typeof ranges["selection_0"] != "undefined"){
                                rangesOld[0] = ranges["selection_0"];
                            }
                            if(typeof ranges["selection_1"] != "undefined"){
                                rangesOld[1] = ranges["selection_1"];
                            }
                            this.setState({ranges:rangesOld},()=>{
                                if(typeof this.props.onChange != "undefined"){
                                    this.props.onChange(rangesOld);
                                }
                            });
                        }}
                        locale={trLocale}
                        staticRanges={defaultStaticRanges}
                        inputRanges={defaultInputRanges}
                    />
                </div>
            </div>
        );
    }
}

