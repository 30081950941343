import ReactGA from 'react-ga';
import Functions from './functions';
import * as firebase from "firebase/app";
import "firebase/messaging";
import "firebase/analytics";

var Action = {
    init: (store) => {
        ReactGA.initialize(Functions.config.google.analytics);

        const initializedFirebaseApp = firebase.initializeApp(Functions.config.firebase);
        const analytics = firebase.analytics();


        if(!Functions.isMobile()){
            const messaging = initializedFirebaseApp.messaging();
            messaging.usePublicVapidKey(Functions.config.firebase.KEY);
            messaging.onMessage(function(payload) {
                console.log("Message received. ", payload);
            });
            Action.notifications = messaging;
        }


        Action.listeners = {
            history:[],
            helper:[],
            showHelp:[]
        };
        Action.linkingId = -1;

        Action.alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i',
            'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't',
            'u', 'v', 'w', 'x', 'y', 'z'];


        Action.getLang = () => {
            return store.model["lang"];
        }

        Action.setLang = (lang) => {
            store.model["lang"] = lang;
            store.emitChange();
        }

        Action.getUser = () => {
            if(store.model.user.isLogged){
                return store.model.user;
            } else {
                return false;
            }
        }
        Action.getUserToken = () => {
            if(store.model.user.isLogged){
                return store.model.user.token;
            } else {
                return false;
            }
        }
        Action.setUserToken = (token) => {
            if(store.model.user.isLogged){
                store.model.user.token = token;
                store.update();
                store.emitChange();
            } else {
                return false;
            }
        }
        Action.setUser = (user) => {
            analytics.setUserId(user.id);
            analytics.setUserProperties({fullName:user.fullName});
            ReactGA.set({ userId: user.id });
            store.model.user = {
                isLogged:true,
                data:user
            }
            store.update();
            store.emitChange();
        }

        Action.updateUser = async (user) => {
            var tmpUser= user;
            Object.keys(user).map(function(objectKey, index) {
                store.model.user.data[objectKey] = user[objectKey];
            });
            try {
                var url = 'xxx?'+Functions.serialize(user);
                let response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
                let responseJson = await response.json();
                if(responseJson.status){
                    store.update();
                } else {

                }
            } catch(error) {

            }
        }

        Action.get = (key) => {
            return store.model[key].reverse();
        }

        Action.getPeople = () => {
            return store.model.people;
        }

        Action.Analytics = (action,data) =>{
            data = (typeof data == "undefined"?{}:data);
            action = (typeof action == "undefined"?"get":action);
            switch(action){
                case "set":
                    store.model.analytics = {
                        sales:{
                            graph:data.sales.graph,
                            total:{
                                reservation:data.sales.total.reservation,
                                cancelled:data.sales.total.cancelled,
                                completed:data.sales.total.completed
                            },
                            earn:{
                                week:data.sales.earn.week,
                                month:data.sales.earn.month
                            }
                        },
                        system:{
                            total:{
                                contractedStores:{
                                    active:data.system.total.contractedStores.active,
                                    passive:data.system.total.contractedStores.passive,
                                },
                                endUsers:{
                                    active:data.system.total.endUsers.active,
                                    passive:data.system.total.endUsers.passive,
                                }
                            }
                        }
                    }
                    store.update();
                    store.emitChange();
                    Action.emit("analytics",store.model.analytics);
                    break;
                default:
                    return store.model.analytics;
                    break;
            }
        }

        Action.getPeopleBySection = () => {
            let p = [];
            for (let i = 0; i < store.model.people.length; i++) {
                let firstName = store.model.people[i].firstName;
                let firstLetter = firstName.substring(0,1);
                let hasFound = false;
                for (let y = 0; y < p.length; y++)
                {
                    if(p[y].title === firstLetter)
                    {
                        p[y].data.push(store.model.people[i]);
                        hasFound = true;
                        break;
                    }

                }
                // new entry
                if(hasFound === false)
                {
                    p.push({title:firstLetter, data:[store.model.people[i]]});
                }
            }
            return p.sort(function(a, b) {
                const aTitle = a.title;
                const bTitle = b.title;
                if (aTitle < bTitle) {
                    return -1;
                }
                if (aTitle > bTitle) {
                    return 1;
                }
                // names must be equal
                return 0;
            });
        }

        Action.getPeopleByProf = (profId) => {
            let temp = [];
            for (let i = 0; i < store.model.people.length; i++) {
                let p = store.model.people[i];

                if( p.profId !== profId)
                    continue;

                temp.push({name:p.firstName + " " + p.lastName, data:p});
            }
            return temp;
        }

        Action.getPeopleBySectionList = () => {
            let temp = [];
            for (let i = 0; i < store.model.people.length; i++) {
                let p = store.model.people[i];
                temp.push({name:p.firstName + " " + p.lastName, data:p});
            }
            return temp;
        }

        Action.addPeople = (data) => {
            store.model.people.push(data);
            store.emitChange();
            Action.emit("people",data);
        }

        Action.removePeople = (key) => {
            var items = [];
            store.model.people.map(function(data,i){
                if(i != key){
                    items.push(data);
                }
            });
            store.model.people = items.reverse();
            store.emitChange();
        }

        Action.register = (target, listener) => {

            if(typeof Action.listeners[target] == "undefined"){
                Action.listeners[target] = [];
            }
            Action.listeners[target].push(listener);
            return listener;
        }
        Action.emit = (target,data) => {
            data = (typeof data == "undefined"?{}:data);
            if(typeof Action.listeners[target] != "undefined"){
                Action.listeners[target].forEach(function(listener,i) {
                    listener(data);
                });
            }
        }
        Action.unregister = function(target) {
            Action.listeners[target] = [];
        }
        Action.logout = () => {
            store.delete();
            window.location.href = "/";
        }
        Action.loading = function(status) {
            Action.emit("loading",status);
        }

        Action.reservations = function(action,items) {
            action = (typeof action == "undefined"?"get":action)
            switch(action){
                case "update":
                    store.model.reservations = items;
                    store.update();
                    store.emitChange();
                    return true;
                    break;
                default:
                    return store.model.reservations;
                    break;
            }
        }

        Action.getDevices = async () => {
            return new Promise(async function(resolve, reject) {
                Action.loading(true);
                var url = Functions.generateURI("api/Reservation/Items",{},Action.getUserToken());
                let response = await fetch(url.address, url.header);
                let responseJson = await response.json();
                if(responseJson.Response) {
                    resolve(responseJson);
                }
                else {
                    //alert("Server Error")
                    reject("Server Error");
                }
                Action.loading(false);
            });
        }

        Action.track = (type,params) => {
            type = (typeof type == "undefined"?"pageview":type);
            params = (typeof params == "undefined"?{}:params);
            switch(type){
                case "event":
                    analytics.logEvent(params.category, params);
                    ReactGA.event(params);
                    break;
                case "timing":
                    ReactGA.timing(params);
                    break;
                default:
                    analytics.setCurrentScreen(window.location.pathname + window.location.search)
                    ReactGA.pageview(window.location.pathname + window.location.search);
                    break;
            }
        }

        return Action;
    }
}
export default Action;
